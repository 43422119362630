import React from 'react'

import moment from 'moment'

import { DataTableColumn, } from '@grupo-tiradentes/web'

import { formatCPF } from '@mec/utils/format/formatCpf'
import { BaixasDePagamentoModel } from '@mec/utils/models/baixasDePagamento'

import TextStatus from '../TextStatus'

const formatMoney = (
  value: number | string
) => Number(value).toLocaleString(
  'pt-br', { style: 'currency', currency: 'BRL' }
)

export const columns: DataTableColumn<BaixasDePagamentoModel>[] = ([
  {
    field: 'id', title: 'Id', hidden: true
  },
  {
    field: 'id', title: '', render: () => '', width: '10px', sorting: false,
  },
  {
    field: 'nomeAluno', title: 'Aluno', sorting: false,
  },
  {
    field: 'cpf', title: 'CPF', render: (item) => formatCPF(item.cpf), sorting: false,
  },
  {
    field: 'matricula', title: 'Matricula', sorting: false,
  },
  {
    field: 'numeroAcordo', title: 'Nº Acordo', sorting: false,
  },
  {
    field: 'empresaParceira', title: 'Empresa Parceira', sorting: false,
  },
  {
    field: 'instituicaoEnsino', title: 'Instituição Ensino', sorting: false,
  },
  {
    field: 'modalidadeEnsino', title: 'Modalidade de Ensino', sorting: false,
  },
  {
    field: 'dataNegociacao',
    title: 'Data Negociação',
    render: (item) => moment(item.dataNegociacao).format('DD/MM/YYYY'),
    sorting: false,
  },
  {
    field: 'totalParcelas', title: 'Total Parc. de Acordos', sorting: false,
  },
  {
    field: 'valorPago', title: 'Valor Pago', render: (item) => formatMoney(item.valorPago), sorting: false,
  },
  {
    field: 'valorMulta', title: 'Multa', render: (item) => formatMoney(item.valorMulta), sorting: false,
  },
  {
    field: 'valorJuros', title: 'Juros', render: (item) => formatMoney(item.valorJuros), sorting: false,
  },
  {
    field: 'saldoDevedor', title: 'Saldo Devedor', render: (item) => formatMoney(item.saldoDevedor), sorting: false,
  },
  {
    field: 'dentroPolitica',
    title: 'Dentro da Política',
    render: ({ politica }) => (<TextStatus status={ politica } />),
    sorting: false,
  },
])
