import {
  CaseReducer,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit'

import { FormInterfacePost } from '@mec/web/src/pages/Empresa/CadastroEmpresa/Tabs/Honorarios/interfaces'

interface IEmpresaState {
  data: {
    dadosPrincipais: any,
    honorarios: FormInterfacePost,
    configuracoes: any,
  }
}

const initialState: IEmpresaState = {
  data: {
    dadosPrincipais: {},
    honorarios: {},
    configuracoes: {},
  },
}

const setDadosPrincipais: CaseReducer<IEmpresaState, PayloadAction<any>> = (
  state,
  { payload: dadosPrincipais }
) => (
  { ...state, data: { ...state.data, dadosPrincipais } }
)

const setHonorarios: CaseReducer<IEmpresaState, PayloadAction<any>> = (
  state,
  { payload: honorarios }
) => (
  { ...state, data: { ...state.data, honorarios } }
)

const setConfiguracoes: CaseReducer<IEmpresaState, PayloadAction<any>> = (
  state,
  { payload: configuracoes }
) => (
  { ...state, data: { ...state.data, configuracoes } }
)

const resetEmpresa: CaseReducer<IEmpresaState, PayloadAction<any>> = (
  state
) => (
  { ...state, data: initialState.data }
)

export const empresaSlyce = createSlice({
  name: 'empresa',
  initialState,
  reducers: {
    setDadosPrincipais,
    setHonorarios,
    setConfiguracoes,
    resetEmpresa
  },
  extraReducers: { },
})

export const {
  setDadosPrincipais: doSetDadosPrincipais,
  setHonorarios: doSetHonorarios,
  setConfiguracoes: doSetConfiguracoes,
  resetEmpresa: doResetEmpresa,
} = empresaSlyce.actions

export default empresaSlyce.reducer
