import React, {
  useState,
  useEffect,
  useCallback
} from 'react'

import get from 'lodash/get'

import {
  Grid,
  useTheme,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'
import { DateRangeOutlined } from '@material-ui/icons'

import {
  AutocompleteInput,
  DateInput,
} from '@grupo-tiradentes/web'

import {
  buscarInstituicoesService,
  buscarEmpresaParceiraService
} from '@mec/services/utils'
import { EmpresaModel } from '@mec/utils/models/empresa'

import FilterWrapper from 'src/containers/FilterWrapper'

import { AutocompleteDataInterface } from '../interfaces'
import { FiltrosRelatorioBaixaInterface } from '../types'
import { useStyles } from './styles'

interface FiltrosProps {
  filtros?: FiltrosRelatorioBaixaInterface,
  resetFiltros?: () => void,
  handleChange: (name: string, value: any) => void
  filtrar?: () => void
}

const Filtros: React.FC<FiltrosProps> = ({
  filtros,
  resetFiltros,
  handleChange,
  filtrar
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const defaultColor = get(theme, 'palette.text.primary')

  const [autocompleteData, setAutocompleteData] = useState<AutocompleteDataInterface>({
    instituicoes: [],
    empresasParceiras: []
  })

  const buscarCargaInicial = useCallback(
    async () => {
      const instituicoes = await buscarInstituicoesService()
      const empresasParceiras = await buscarEmpresaParceiraService()

      setAutocompleteData({
        ...autocompleteData,
        instituicoes: [{ id: 'Todos', instituicao: ' Todos' }, ...instituicoes],
        empresasParceiras: [{
          id: 'Todos', nomeFantasia: ' Todos', razaoSocial: '', numeroContrato: '', cnpj: '', contatos: []
        }, ...empresasParceiras],
      })
    },
    [autocompleteData]
  )

  const selecionaTodasOpcoes = (
    valorCampo: any[],
    todosString = 'Todos'
  ): any[] => valorCampo.filter((item) => item.id !== todosString).map((item) => item.id)

  const onChangeAutoCompleteInstituicao = (name: string, e: any): void => {
    if (String(e?.target?.value).includes('Todos')) {
      const todasOpcoes = selecionaTodasOpcoes(autocompleteData.instituicoes)
      return handleChange(name, todasOpcoes)
    }

    return handleChange(name, e.target.value)
  }

  const onChangeAutoCompleteEmpresa = (name: string, e: any): void => {
    if (String(e?.target?.value).includes('Todos')) {
      const todasOpcoes = selecionaTodasOpcoes(autocompleteData.empresasParceiras)
      return handleChange(name, todasOpcoes)
    }

    return handleChange(name, e.target.value)
  }

  useEffect(
    () => {
      buscarCargaInicial()
    },
    // eslint-disable-next-line
    []
  )

  return (
    <FilterWrapper
      onFilter={ filtrar }
      onResetFilter={ resetFiltros }
    >
      <Grid
        container
        spacing={ 2 }
        className={ classes.row }
        justifyContent="space-around"
      >
        <Grid item lg={ 3 } md={ 6 } sm={ 6 } xs={ 12 }>
          <AutocompleteInput
            id="filtros-instituicao-id"
            size="small"
            name="instituicaoId"
            label="Instituição de Ensino"
            value={ filtros.instituicaoId }
            onChange={ (e) => onChangeAutoCompleteInstituicao('instituicaoId', e) }
            options={ autocompleteData.instituicoes.map((instituicao) => ({
              label: instituicao.instituicao,
              value: instituicao.id
            })) }
            inputProps={ { autocomplete: 'off' } }
            fullWidth
            showSearchIcon
            disableOrdenation
            borderRadius={ 10 }
            defaultColor={ defaultColor }
            autocompleteProps={ { limitTags: 1 } }
          />
        </Grid>
        <Grid item lg={ 3 } md={ 6 } sm={ 6 } xs={ 12 }>
          <AutocompleteInput
            size="small"
            name="empresaParceiraId"
            value={ filtros.empresaParceiraId }
            label="Empresa de cobrança"
            onChange={ (e) => onChangeAutoCompleteEmpresa('empresaParceiraId', e) }
            options={ autocompleteData.empresasParceiras.map((empresa: EmpresaModel) => ({
              label: empresa.nomeFantasia,
              value: empresa.id
            })) }
            inputProps={ { autocomplete: 'off' } }
            fullWidth
            showSearchIcon
            disableOrdenation
            borderRadius={ 10 }
            defaultColor={ defaultColor }
            autocompleteProps={ { limitTags: 1 } }
          />
        </Grid>
        <Grid item lg={ 2 } md={ 6 } sm={ 6 } xs={ 12 }>
          <DateInput
            size="small"
            label="Data início"
            name="dataInicial"
            value={ filtros.dataInicial || '' }
            onChange={ (e) => handleChange('dataInicial', e.target.value) }
            autoComplete="off"
            fullWidth
            InputProps={ { endAdornment: (<DateRangeOutlined color="primary" />) } }
            borderRadius={ 10 }
            defaultColor={ defaultColor }
          />
        </Grid>
        <Grid item lg={ 2 } md={ 6 } sm={ 6 } xs={ 12 }>
          <DateInput
            size="small"
            label="Data fim"
            name="dataFinal"
            value={ filtros.dataFinal || '' }
            onChange={ (e) => handleChange('dataFinal', e.target.value) }
            autoComplete="off"
            fullWidth
            InputProps={ { endAdornment: (<DateRangeOutlined color="primary" />) } }
            borderRadius={ 10 }
            defaultColor={ defaultColor }
          />
        </Grid>
        <Grid item lg={ 2 } md={ 12 } sm={ 12 } xs={ 12 }>
          <FormControlLabel
            value={ filtros.erro || null }
            control={ <Checkbox checked={ filtros.erro } color="primary" /> }
            label="Com falha de integração"
            labelPlacement="end"
            name="erro"
            onChange={ (e) => handleChange('erro', get(e, 'target.checked')) }
          />
        </Grid>
      </Grid>
    </FilterWrapper>
  )
}

export default Filtros
