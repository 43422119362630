const routes = {
  empresa: 'empresa-parceira',
  endereco: 'endereco',
  instituicao: 'instituicao',
  modalidade: 'modalidade',
  honorario: 'honorarios',
  bancos: 'banco',
  situacaoAluno: 'situacao-aluno',
  tituloAvulso: 'titulo-avulso',
  tipoTitulo: 'tipo-titulo',
  curso: 'curso',
  parametroEnvio: 'parametro-envio',
  podeReprocessar: 'parametro-envio/reprocessar',
  regraDeNegociacao: 'regra-negociacao',
  conflitos: 'conflito',
  conflitosDetalhes: 'conflito-detalhe',
  baixaCobranca: 'baixa-pagamento',
  relatorioDeBaixaDePagamento: 'baixas',
  convenioPorInstituicao: 'convenio/por-instituicao',
  reprocessarAcordoCobranca: 'baixa-cobranca/reprocessar-acordo-cobranca',
}

export default routes
