/* eslint-disable no-param-reassign */
import React from 'react'

import { BaixasDePagamentoModel } from '@mec/utils/models/baixasDePagamento'

import SimpleTable from 'src/components/SimpleTable'
import Subtitle from 'src/components/Subtitle'

import { useStyles } from './styles'
import {
  getNovasParcelasNegociadasColumns,
  subtableColumnsParcelasAcordadas
} from './tableColumns'

interface SubtableBaixasDePagamentoProps {
  item: BaixasDePagamentoModel,
  onClickBaixaDeBanco: (id: string | number) => void,
  onClickRegularizacao: (id: string | number) => void,
}

const SubtableBaixasDePagamento: React.FC<SubtableBaixasDePagamentoProps> = ({
  item,
  onClickBaixaDeBanco,
  onClickRegularizacao
}) => {
  const classes = useStyles()
  const columnsNovasParcelasNegociadas = getNovasParcelasNegociadasColumns({
    onClickBaixaDeBanco,
    onClickRegularizacao
  })

  const handleData = (value: BaixasDePagamentoModel) => {
    // eslint-disable-next-line array-callback-return
    value?.parcelasNegociadas.map((v) => {
      v.idBaixaPagamento = value.id
      v.numeroAcordo = value.numeroAcordo
      v.instituicaoId = value.instituicaoId
    })

    return value.parcelasNegociadas
  }

  return (
    <div className={ classes.container }>
      <Subtitle>Parcelas Acordadas</Subtitle>
      <SimpleTable
        items={ item.parcelasAcordadas }
        columns={ subtableColumnsParcelasAcordadas }
        style={ { width: 'auto' } }
      />

      <Subtitle>Novas Parcelas Negociadas</Subtitle>
      <SimpleTable
        items={ handleData(item) }
        columns={ columnsNovasParcelasNegociadas }
        style={ { width: 'auto' } }
      />
    </div>
  )
}

export default SubtableBaixasDePagamento
