import { makeStyles } from '@material-ui/core'

export default makeStyles({
  '@global': {
    // add global css here

    'html, body, #root': {
      scrollBehavior: 'smooth',
      padding: 0,
      margin: 0,
    },

    body: { overflow: 'auto' },

    a: {
      color: 'inherit',
      textDecoration: 'none',
    },

    form: { width: '100%' },

    '*': { boxSizing: 'border-box' },
  },
})
