import { AxiosResponse } from 'axios'

import { SituacaoAlunoModel } from '@mec/utils/models/aluno'
import { BancoModel } from '@mec/utils/models/banco'
import { CursoModel } from '@mec/utils/models/curso'
import { EmpresaModel } from '@mec/utils/models/empresa'
import { EnderecoModel } from '@mec/utils/models/endereco'
import { InstituicaoModel } from '@mec/utils/models/instituicao'
import { ModalidadeModel } from '@mec/utils/models/modalidade'
import {
  TipoTituloModel,
  TituloAvulsoModel
} from '@mec/utils/models/titulo'

import { api } from '..'

import routes from '../routes'

const buscarModalidadePorInstituicaoService = async (
  instituicaoId: string | number
): Promise<ModalidadeModel[]> => {
  const { data: response } = await api.get(`${ routes.modalidade }/por-instituicao/${ instituicaoId }`)
  return response
}

const buscarInstituicoesService = async (): Promise<InstituicaoModel[]> => {
  const { data: response } = await api.get(routes.instituicao)
  return response
}

const buscarBancosService = async (): Promise<BancoModel[]> => {
  const { data: response } = await api.get(routes.bancos)
  return response
}

const buscarSituacaoAlunoService = async (): Promise<SituacaoAlunoModel[]> => {
  const { data: response } = await api.get(routes.situacaoAluno)
  return response
}

const buscarTipoTituloService = async (): Promise<TipoTituloModel[]> => {
  const { data: response } = await api.get(routes.tipoTitulo)
  return response
}

const buscarTituloAvulsoService = async (): Promise<TituloAvulsoModel[]> => {
  const { data: response } = await api.get(routes.tituloAvulso)
  return response
}

const buscarCursoPorInstituicaoModalidadeService = async (
  instituicaoId: string | number,
  modalidadeId: string | number,
): Promise<CursoModel[]> => {
  const { data: response } = await api.get(
    `${ routes.curso }/por-instituicao-modalidade/${ instituicaoId }/${ modalidadeId }`
  )
  return response
}

const buscarEmpresaParceiraService = async (): Promise<EmpresaModel[]> => {
  const { data: response } = await api.get(routes.empresa)
  return response.items
}

const buscarEnderecoPorCEP = async (cep: string): Promise<EnderecoModel> => {
  const { data: response } = await api.get(`${ routes.endereco }/busca-por-cep/${ cep }`)
  return response
}

const verificarCnpjCadastrado = async (
  cnpj: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  params: any
): Promise<AxiosResponse<any>> => api.get(`${ routes.empresa }/verifica-cnpj-cadastrado/${ cnpj }`, { params })

export {
  buscarCursoPorInstituicaoModalidadeService,
  buscarModalidadePorInstituicaoService,
  buscarEmpresaParceiraService,
  buscarInstituicoesService,
  buscarSituacaoAlunoService,
  buscarTituloAvulsoService,
  buscarTipoTituloService,
  buscarBancosService,
  buscarEnderecoPorCEP,
  verificarCnpjCadastrado
}
