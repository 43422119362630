import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  row: {
    width: '100%',
    margin: 0,
  },
}))

export { useStyles }
