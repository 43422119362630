import { FC } from 'react'

import map from 'lodash/map'

import List from '@material-ui/core/List'

import { useRoutes } from 'src/routes/authenticated.routes'

import ItemMenu from './ItemMenu'

interface Props {
  open: boolean;
}

const MenuLeft: FC<Props> = ({ open }) => {
  const { menus } = useRoutes()

  return (
    <List>
      {map(menus, (menu) => (
        <ItemMenu
          key={ menu.key }
          menu={ menu }
          menuOpen={ open }
        />
      ))}
    </List>
  )
}

export default MenuLeft
