/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useRef,
  useEffect,
  useState
} from 'react'

import moment from 'moment'

import { useModal } from '@grupo-tiradentes/core'
// import {
//   DataTableQuery,
//   DataTableQueryResult,
// } from '@grupo-tiradentes/web'

import { buscarBaixasDePagamentoService } from '@mec/services/baixasDePagamento'
// import { BaixasDePagamentoModel } from '@mec/utils/models/baixasDePagamento'

import BaixaDeBancoManualModal from '../Modal/BaixaDeBancoManualModal'
import RegularizacaoModal from '../Modal/RegularizacaoModal'
import FiltrosBaixasDePagamento from './Filtros'
import TabelaBaixasDePagamento from './Tabela'
import { columns } from './Tabela/tableColumns'

interface IOrdenacao {
  ordenarPor: string,
  sentidoOrdenacao: string
}

const ListagemBaixaDePagamento: React.FC = () => {
  const tableRef = useRef<any>(null)
  const { createModal } = useModal()
  const [filtros, setFiltros] = useState({})
  const [dadosTabela, setDadosTabela] = useState([])
  const [loading, setLoading] = useState(false)
  const [paginaTabela, setPaginaTabela] = useState(1)
  const [totalItemsTabela, setTotalItemsTabela] = useState(0)
  const [ordenacao, setOrdenacao] = useState<IOrdenacao>({} as IOrdenacao)

  const filtrar = useCallback(
    async () => {
      setLoading(true)

      const responseData = await buscarBaixasDePagamentoService({
        pagina: 1,
        limite: 10,
        ordenarPor: ordenacao?.ordenarPor || 'id',
        sentidoOrdenacao: ordenacao?.sentidoOrdenacao || 'asc',
        ...filtros
      })

      setDadosTabela(responseData.items)
      setPaginaTabela(responseData.paginaAtual)
      setTotalItemsTabela(responseData.totalItems)
      setLoading(false)
    },
    [filtros, ordenacao?.ordenarPor, ordenacao?.sentidoOrdenacao]
  )

  const getDadosTabela = useCallback(
    async () => {
      setLoading(true)
      const responseData = await buscarBaixasDePagamentoService({
        pagina: 1,
        limite: 10,
        ...filtros
      })

      setDadosTabela(responseData.items)
      setPaginaTabela(responseData.paginaAtual)
      setTotalItemsTabela(responseData.totalItems)
      setLoading(false)
    },
    [filtros]
  )

  useEffect(
    () => {
      getDadosTabela()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const atualizarTabela = (dadosRegularizacao) => {
    const dadosModal = dadosTabela

    dadosModal.map((baixa) => {
      if (baixa?.id === dadosRegularizacao.idBaixaPagamento) {
        if (dadosRegularizacao.situacaoPagamento === 'B') {
          baixa.valorPago += dadosRegularizacao.valorParcela
        }

        baixa.parcelasNegociadas.map((parcelaNegociada) => {
          if (parcelaNegociada.id === dadosRegularizacao.idParcelaNegociada) {
            parcelaNegociada.tipoPagamento = dadosRegularizacao.tipoPagamento
            parcelaNegociada.dataBaixa = dadosRegularizacao.dataPagamento
            parcelaNegociada.dataPagamento = dadosRegularizacao.dataPagamento
            parcelaNegociada.valorPago = dadosRegularizacao.valorPago
            parcelaNegociada.situacaoPagamento = dadosRegularizacao.situacaoPagamento
            parcelaNegociada.valorJurosMulta = dadosRegularizacao.jurosMulta
          }
        })
      }
    })

    setDadosTabela(dadosModal)
  }

  const onClickBaixaDeBanco = (id: any) => {
    const dadosCarregadosBaixaDeBanco = {
      motivo: id?.motivo,
      dataBaixa: moment(id?.dataBaixa).add('hours', 3).format('DD/MM/YYYY'),
      valorBaixa: id?.valorPago,
      codigoConvenio: id?.codigoConvenio
    }

    createModal({
      id: 'baixa-de-banco-modal',
      Component: BaixaDeBancoManualModal,
      props: {
        baixaDePagamentoId: id,
        title: 'Baixa de banco manual',
        text: 'Tem certeza que deseja cancelar?',
        dadosCarregadosBaixaDeBanco: id.situacaoPagamento !== 'N' ? dadosCarregadosBaixaDeBanco : {},
        onConfirmarBaixaManual: (novosDadosTabela) => atualizarTabela(novosDadosTabela)
      }
    })
  }

  const onClickRegularizacao = (id: any) => {
    const dadosCarregadosRegularizacao = {
      motivo: id?.motivo,
      descontoTotal: id?.descontoTotal,
      dataBaixa: moment(id?.dataBaixa).add('hours', 3).format('DD/MM/YYYY'),
      valorBaixa: id?.valorPago,
    }

    createModal({
      id: 'baixa-de-banco-modal',
      Component: RegularizacaoModal,
      props: {
        baixaDePagamentoId: id,
        title: 'Regularização',
        dadosCarregadosRegularizacao: id.situacaoPagamento !== 'N' ? dadosCarregadosRegularizacao : {},
        onConfirmarRegularizacao: (novosDadosTabela) => atualizarTabela(novosDadosTabela)
      }
    })
  }

  const onChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true)
      const responseData = await buscarBaixasDePagamentoService({
        pagina: page + 1,
        limite: pageSize,
        ordenarPor: ordenacao?.ordenarPor || 'id',
        sentidoOrdenacao: ordenacao?.sentidoOrdenacao || 'asc',
        ...filtros
      })

      setDadosTabela(responseData.items)
      setPaginaTabela(responseData.paginaAtual)
      setTotalItemsTabela(responseData.totalItems)
      setLoading(false)
    },
    [filtros, ordenacao?.ordenarPor, ordenacao?.sentidoOrdenacao]
  )

  const onOrderChange = useCallback(
    async (orderBy: number, orderDirection: 'desc' | 'asc') => {
      setLoading(true)
      const t = columns.find((c, i) => i === orderBy)
      setOrdenacao({ ordenarPor: t.field, sentidoOrdenacao: orderDirection } as IOrdenacao)

      const responseData = await buscarBaixasDePagamentoService({
        pagina: 1,
        limite: 10,
        ordenarPor: t?.field || 'id',
        sentidoOrdenacao: orderDirection || 'asc',
        ...filtros
      })

      setDadosTabela(responseData.items)
      setPaginaTabela(responseData.paginaAtual)
      setTotalItemsTabela(responseData.totalItems)
      setLoading(false)
    },
    [filtros]
  )

  return (
    <>
      <FiltrosBaixasDePagamento
        updateFilters={ setFiltros }
        onFilter={ filtrar }
      />
      <div style={ { marginTop: 20 } }>
        <TabelaBaixasDePagamento
          onOrderChange={ onOrderChange }
          loading={ loading }
          onChangePage={ onChangePage }
          page={ paginaTabela - 1 }
          totalCount={ totalItemsTabela }
          ref={ tableRef }
          data={ dadosTabela }
          onClickBaixaDeBanco={ onClickBaixaDeBanco }
          onClickRegularizacao={ onClickRegularizacao }
        />
      </div>
    </>
  )
}

export default ListagemBaixaDePagamento
