import { PayloadAction } from '@reduxjs/toolkit'

import { EmpresaModel as EmpresaData } from '@mec/utils/models/empresa'

/**
 * Action types
 */
export enum EmpresaTypes {
  CRIAR_EMPRESA = '@@EMPRESA/CRIAR_EMPRESA',
  EDITAR_EMPRESA = '@@EMPRESA/EDITAR_EMPRESA',
  DELETAR_EMPRESA = '@@EMPRESA/DELETAR_EMPRESA',
}

// /**
//  * State type
//  */

// export interface EmpresaState { }

/**
 * Interfaces
 */
export interface Contato {
  id?: number
  contato: string,
  email: string,
  telefone: string
}

interface DefaultActions {
  onSuccess?: () => void
  onFailure?: (e?: any) => void
}

export interface CriarEmpresaData extends DefaultActions {
  data : Omit<EmpresaData, 'id' | 'aditivoContrato'>
}
export type ICriarEmpresa = PayloadAction<CriarEmpresaData>;
export interface EditarEmpresaData extends DefaultActions {
  data : EmpresaData
}
export type IEditarEmpresa = PayloadAction<EditarEmpresaData>;
export interface DeletarEmpresaData extends DefaultActions {
  id : number
}
export type IDeletarEmpresa = PayloadAction<DeletarEmpresaData>;
