import React, { forwardRef } from 'react'

import { DataTable } from '@grupo-tiradentes/web'

import { BaixasDePagamentoModel } from '@mec/utils/models/baixasDePagamento'

import TableWrapper from 'src/containers/TableWrapper'

import SubtableBaixasDePagamento from '../SubTabela'
import { columns } from './tableColumns'

interface TabelaBaixasDePagamentoProps {
  // eslint-disable-next-line react/no-unused-prop-types
  ref: React.Ref<any>,
  data: any,
  onClickBaixaDeBanco: (id: string | number) => void,
  onClickRegularizacao: (id: string | number) => void,
  totalCount: number
  page: number
  onChangePage:(page: number, pageSize: number) => void
  loading:boolean,
  onOrderChange: (orderBy: number, orderDirection: 'desc' | 'asc') => void
}

const TabelaBaixasDePagamento: React.FC<TabelaBaixasDePagamentoProps> = forwardRef((
  {
    data, onClickBaixaDeBanco, onClickRegularizacao, totalCount, page, onChangePage, loading, onOrderChange
  }: TabelaBaixasDePagamentoProps, ref
) => (
  <TableWrapper>
    <DataTable
      onOrderChange={ onOrderChange }
      isLoading={ loading }
      onChangePage={ onChangePage }
      page={ page }
      totalCount={ totalCount }
      title="Baixas de Pagamento"
      tableRef={ ref }
      columns={ columns }
      data={ data }
      detailPanel={ (item: BaixasDePagamentoModel) => (
        <SubtableBaixasDePagamento
          item={ item }
          onClickBaixaDeBanco={ onClickBaixaDeBanco }
          onClickRegularizacao={ onClickRegularizacao }
        />
      ) }
    />
  </TableWrapper>
))

export default TabelaBaixasDePagamento
