import {
  useMemo,
  useState,
  useCallback,
  FC
} from 'react'
import { NavLink } from 'react-router-dom'

import map from 'lodash/map'

import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import Fade from '@material-ui/core/Fade'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import clsx from 'clsx'
import ArrowDownIcon from 'mdi-material-ui/ChevronDown'
import ArrowUpIcon from 'mdi-material-ui/ChevronUp'

import { IconButton } from '@grupo-tiradentes/web'

import { Menu } from 'src/routes/types'

import useStyles from './styles'

interface Props {
  menu: Menu;
  menuOpen: boolean;
}

const ItemMenu: FC<Props> = ({ menu, menuOpen }) => {
  const classes = useStyles()

  const [itemOpen, setItemOpen] = useState(false)

  const onToggleItem = useCallback(
    () => setItemOpen((old) => !old),
    []
  )

  const item = useMemo(
    () => {
      let content = (
        <>
          {menu.Icon && (
            <ListItemIcon className={ classes.listItemIcon }>
              <menu.Icon />
            </ListItemIcon>
          )}
          {menuOpen && (
            <ListItemText
              primary={ menu.title }
              className={ classes.listItemText }
            />
          )}
        </>
      )

      if (menu.defaultPath) {
        content = (
          <NavLink
            to={ menu.defaultPath }
            exact={ menu.exact }
            className={ clsx(classes.item, classes.itemColor) }
            activeClassName={ classes.itemActive }
          >
            {content}
          </NavLink>
        )
      }

      return content
    },
    [classes, menu, menuOpen]
  )

  const ArrowIcon = useMemo(
    () => (itemOpen ? ArrowUpIcon : ArrowDownIcon),
    [itemOpen]
  )

  const listItem = useMemo(
    () => (
      <ListItem
          // eslint-disable-next-line no-unneeded-ternary
        button={ (!menuOpen || !itemOpen) as true | undefined }
        onClick={ () => menuOpen && setItemOpen(true) }
        classes={ { gutters: classes.listItemGutters } }
        className={ clsx(
          classes.listItem,
          { [classes.listItemOpen]: menuOpen && itemOpen }
        ) }
      >
        <div className={ classes.item }>
          {item}
          {menuOpen && (
            <Fade in={ menuOpen } timeout={ 500 }>
              <IconButton
                color="primary"
                aria-label="open"
                size="small"
                className={ classes.arrowIconButton }
                onClick={ (e) => {
                  e.stopPropagation()
                  onToggleItem()
                } }
              >
                <ArrowIcon fontSize="small" />
              </IconButton>
            </Fade>
          )}
        </div>

        {menuOpen && itemOpen && (
          <div
            className={ classes.subItems }
          >
            <Divider style={ { margin: '8px 5px 2px' } } />
            {map(menu.subMenu, (subMenu) => (
              <NavLink
                key={ subMenu.path }
                to={ subMenu.path }
                exact={ subMenu.exact }
                className={ classes.subItem }
                activeClassName={ classes.subItemActive }
                onClick={ (e) => e.stopPropagation() }
              >
                <ListItem
                  button
                  className={ classes.listSubItem }
                >
                  <ListItemText
                    primary={ subMenu.title }
                    className={ classes.subItemText }
                  />
                </ListItem>
              </NavLink>
            ))}
          </div>
        )}

      </ListItem>
    ),
    [ArrowIcon, classes, item, itemOpen, menu.subMenu, menuOpen, onToggleItem]
  )

  if (menuOpen && itemOpen) {
    return (
      <Card className={ classes.card }>
        {listItem}
      </Card>
    )
  }

  return listItem
}

export default ItemMenu
