import {
  takeLatest,
  call
} from 'redux-saga/effects'

import {
  criarEmpresaService,
  alterarEmpresaService,
  deletarEmpresaService
} from '@mec/services/empresa'

import {
  EmpresaTypes,
  ICriarEmpresa,
  IEditarEmpresa,
  IDeletarEmpresa
} from './empresa.types'

function* doCriarEmpresa({ payload }: ICriarEmpresa) {
  const {
    data,
    onSuccess = () => null,
    onFailure = () => null
  } = payload
  try {
    yield call(criarEmpresaService, data)
    yield call(onSuccess)
  } catch (e) {
    yield call(onFailure, e)
  }
}

function* doAlterarEmpresa({ payload }: IEditarEmpresa) {
  const {
    data,
    onSuccess = () => null,
    onFailure = () => null
  } = payload
  try {
    yield call(alterarEmpresaService, data as any)
    yield call(onSuccess)
  } catch (e) {
    yield call(onFailure, e)
  }
}

function* doDeletarEmpresa({ payload }: IDeletarEmpresa) {
  const {
    id,
    onSuccess = () => null,
    onFailure = () => null
  } = payload
  try {
    yield call(deletarEmpresaService, id)
    yield call(onSuccess)
  } catch (e) {
    yield call(onFailure, e)
  }
}

export default [
  takeLatest(EmpresaTypes.CRIAR_EMPRESA, doCriarEmpresa),
  takeLatest(EmpresaTypes.EDITAR_EMPRESA, doAlterarEmpresa),
  takeLatest(EmpresaTypes.DELETAR_EMPRESA, doDeletarEmpresa)
]
