import React, {
  useCallback,
  useRef
} from 'react'

import {
  DataTable,
  DataTableQuery,
  DataTableQueryResult,
} from '@grupo-tiradentes/web'

import { buscarConflitosParametroDeEnvioService, } from '@mec/services/parametroDeEnvio'
import { ParametroDeEnvioConflitoModel, } from '@mec/utils/models/parametroDeEnvio'

import TableWrapper from 'src/containers/TableWrapper'

import SubtableDetails from './subtableDetails'
import { columns } from './tableColumns'

const ListagemConflitos: React.FC = () => {
  const tableRef = useRef<any>(null)

  const data = useCallback(
    async (
      query: DataTableQuery<ParametroDeEnvioConflitoModel>
    ): Promise<DataTableQueryResult<ParametroDeEnvioConflitoModel>> => {
      const responseData = await buscarConflitosParametroDeEnvioService({
        pagina: query.page + 1,
        limite: query.pageSize,
        ordenarPor: query.orderBy?.field || '',
        sentidoOrdenacao: query.orderDirection || '',
      })

      return {
        data: responseData.items,
        page: responseData.paginaAtual - 1,
        totalCount: responseData.totalItems
      }
    },
    []
  )

  return (
    <TableWrapper>
      <DataTable
        title="Listagem de Conflitos"
        tableRef={ tableRef }
        columns={ columns }
        data={ data }
        detailPanel={ (item: ParametroDeEnvioConflitoModel) => (<SubtableDetails item={ item } />) }
      />
    </TableWrapper>
  )
}

export default ListagemConflitos
