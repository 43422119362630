import React, {
  useState,
  useCallback,
  useRef
} from 'react'

import {
  DataTableQuery,
  DataTableQueryResult,
} from '@grupo-tiradentes/web'

import { buscarRelatoriosDeBaixasDePagamentoService } from '@mec/services/baixasDePagamento'
import { RelatorioBaixaDePagamentoModel } from '@mec/utils/models/baixasDePagamento'

import Filtros from './Filtros'
import Tabela from './Tabela'
import { FiltrosRelatorioBaixaInterface } from './types'

const RelatorioBaixaDePagamento: React.FC = () => {
  const tableRef = useRef<any>(null)

  const [filtros, setFiltros] = useState<FiltrosRelatorioBaixaInterface>({
    instituicaoId: [],
    empresaParceiraId: [],
    dataInicial: '',
    dataFinal: '',
    erro: null,
  })

  const handleChange = (name: string, value: any) => {
    setFiltros((old) => ({ ...old, [name]: value }))
  }

  const filtrar = () => {
    tableRef.current.onQueryChange()
  }

  const data = useCallback(
    async (
      query: DataTableQuery<RelatorioBaixaDePagamentoModel>
    ): Promise<DataTableQueryResult<RelatorioBaixaDePagamentoModel>> => {
      const responseData = await buscarRelatoriosDeBaixasDePagamentoService({
        pagina: query.page + 1,
        limite: query.pageSize,
        ordenarPor: query.orderBy?.field || 'id',
        sentidoOrdenacao: query.orderDirection || 'asc',
        ...filtros
      })

      return {
        data: responseData.items,
        page: responseData.paginaAtual - 1,
        totalCount: responseData.totalItems
      }
    },
    [filtros]
  )

  return (
    <>
      <Filtros
        handleChange={ handleChange }
        filtros={ filtros }
        filtrar={ filtrar }
        resetFiltros={ () => setFiltros(
          {
            instituicaoId: [],
            empresaParceiraId: [],
            dataInicial: '',
            dataFinal: '',
            erro: undefined
          }
        ) }
      />
      <div style={ { marginTop: '20px' } }>
        <Tabela
          ref={ tableRef }
          data={ data }
          filtrar={ filtrar }
        />
      </div>
    </>
  )
}

export default RelatorioBaixaDePagamento
