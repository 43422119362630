import {
  FC,
  useEffect,
  useState,
  useCallback
} from 'react'

import PhoneIcon from '@ant-design/icons/PhoneOutlined'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import ArrowTopIcon from 'mdi-material-ui/ArrowUpCircleOutline'

import { IconButton } from '@grupo-tiradentes/web'

import customData from '@mec/web/package.json'

import logo from 'src/assets/images/logo.png'

import useStyles from './styles'

const Footer: FC = () => {
  const classes = useStyles()

  const [scrollVisibility, setScrollVisibility] = useState(false)

  const listener = useCallback(
    () => {
      if (window.pageYOffset > 50) {
        setScrollVisibility(true)
      } else {
        setScrollVisibility(false)
      }
    },
    []
  )

  useEffect(
    () => {
      document.addEventListener('scroll', listener)
      return () => {
        document.removeEventListener('scroll', listener)
      }
    },
    [listener]
  )

  return (
    <div className={ classes.container }>
      <Card classes={ { root: classes.card } }>
        <CardContent classes={ { root: classes.cardContent } }>

          <img
            alt="Grupo Tiradentes"
            src={ logo }
            className={ classes.logo }
          />

          <div className={ classes.spacer } />

          <div className={ classes.infoContainer }>
            <Typography variant="caption" color="primary">
              <b>
                Diretoria Financeira Contas a Receber
              </b>
            </Typography>
            <Typography variant="caption" className={ classes.phonesContainer }>
              <PhoneIcon className={ classes.phoneIcon } />
              (79) 3218 - 2155
            </Typography>
            <Typography variant="caption">
              Versão:
              {' '}
              { customData?.version }
            </Typography>
          </div>

          <div
            className={ classes.buttonScrollToTopContainer }
            style={ { opacity: scrollVisibility ? 1 : 0 } }
          >
            <IconButton
              color="inherit"
              aria-label="Topo"
              tooltip={ scrollVisibility && 'Voltar ao topo' }
              onClick={ () => window.scrollTo(0, 0) }
              size="small"
              className={ classes.buttonScrollToTop }
              disabled={ !scrollVisibility }
            >
              <ArrowTopIcon fontSize="small" />
            </IconButton>
          </div>

        </CardContent>
      </Card>
    </div>
  )
}

export default Footer
