import React, { forwardRef } from 'react'

import { DataTable, } from '@grupo-tiradentes/web'

import { RelatorioBaixaDePagamentoModel, } from '@mec/utils/models/baixasDePagamento'

import TableWrapper from 'src/containers/TableWrapper'

import SubtableDetails from './subtableDetails'
import { columns } from './tableColumns'

interface TabelaBaixasDePagamentoProps {
  // eslint-disable-next-line react/no-unused-prop-types
  ref: React.Ref<any>,
  data?: any,
  filtrar?: () => void
}

const tabelaRelatorioBaixaDePagamento: React.FC<TabelaBaixasDePagamentoProps> = forwardRef((
  { data, filtrar }: TabelaBaixasDePagamentoProps, ref
) => (
  <TableWrapper>
    <DataTable
      title="Relatório de baixa de pagamento"
      tableRef={ ref }
      columns={ columns }
      data={ data }
      detailPanel={ (item: RelatorioBaixaDePagamentoModel) => (<SubtableDetails item={ item } filtrar={ filtrar } />) }
    />
  </TableWrapper>
))

export default tabelaRelatorioBaixaDePagamento
