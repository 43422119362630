import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  wrapper: { padding: 24 },
  buttonContainer: { marginTop: 20 },
  title: {
    marginBottom: 15,
    marginTop: 0,
    fontSize: 18,
    fontWeight: 500,
  },
  divider: {
    height: 1,
    marginTop: 25,
    marginBottom: 25,
  },
})
