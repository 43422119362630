import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  modalBackground: { backgroundColor: '#F2F2F2' },
  title: {
    fontWeight: 600,
    fontSize: 20,
    padding: '12px 13px 0 13px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  codeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
  },
  spanText: { color: theme.palette.primary.main },
  spanValue: { color: theme.palette.text.primary },
  radioFormGroup: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    columnGap: 20
  }
}))

export default useStyles
