import axios, { AxiosInstance } from 'axios'

import { keycloak } from '@mec/utils/auth/auth'

// Timeout foi para 20 minutos para atender regra com mais de 18k de cursos
const api: AxiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL, timeout: 1000 * 12000 })

api.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${ keycloak?.token }`

  return request
})

// Envia os dados do Keycloak para serem validados e salvos
api.interceptors.request.use((request) => {
  request.headers.user = keycloak?.tokenParsed?.email
  request.headers.keycloak = JSON.stringify(keycloak?.tokenParsed)
  return request
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      keycloak.logout()
      return Promise.resolve()
    }
    return Promise.reject(error)
  }
)

const requests = { get: async (route: string):Promise<any> => api.get(route).then((res: any) => res.data) }

export {
  api,
  requests
}
