import {
  ParametroDeEnvioConflitoDetalheModel,
  ParametroDeEnvioConflitoModel,
  ParametroDeEnvioDTO,
  ParametroDeEnvioModel,
  ParametroDeEnvioResponse
} from '@mec/utils/models/parametroDeEnvio'

import { api } from '..'

import routes from '../routes'
import { List } from '../types'

const buscarListaParametroDeEnvioService = async (params?: unknown): Promise<List<ParametroDeEnvioModel>> => {
  const { data: response } = await api.get(routes.parametroEnvio, { params })
  return response
}

const buscarParametroDeEnvioService = async (id: number | string): Promise<ParametroDeEnvioModel> => {
  const { data: response } = await api.get(`${ routes.parametroEnvio }/${ id }`)
  return response
}

const cadastrarParametroDeEnvioService = async (dto: ParametroDeEnvioDTO): Promise<ParametroDeEnvioResponse> => (
  api.post(routes.parametroEnvio, dto)
)

const editarParametroDeEnvioService = async (dto: ParametroDeEnvioDTO): Promise<ParametroDeEnvioResponse> => (
  api.put(routes.parametroEnvio, dto)
)

const excluirParametroDeEnvioService = async (id: string | number): Promise<ParametroDeEnvioResponse> => (
  api.delete(`${ routes.parametroEnvio }/${ id }`)
)

const buscarConflitosParametroDeEnvioService = async (
  params?: unknown
): Promise<List<ParametroDeEnvioConflitoModel>> => {
  const { data: response } = await api.get(routes.conflitos, { params })
  return response
}

const buscarDetalhesConflitosParametroDeEnvioService = async (
  id: string | number
): Promise<Array<ParametroDeEnvioConflitoDetalheModel>> => {
  const { data: response } = await api.get(`${ routes.conflitosDetalhes }/${ id }`)
  return response
}

const ReprocessarParametroEnvioService = async (
  mensagem: any
) : Promise<void> => api.post(`${ routes.podeReprocessar }`, mensagem)

export {
  buscarListaParametroDeEnvioService,
  buscarParametroDeEnvioService,
  cadastrarParametroDeEnvioService,
  editarParametroDeEnvioService,
  excluirParametroDeEnvioService,
  buscarConflitosParametroDeEnvioService,
  buscarDetalhesConflitosParametroDeEnvioService,
  ReprocessarParametroEnvioService
}
