import React from 'react'

import { useStyles } from './styles'

interface TextStatusProps {
  status: boolean
}

const TextStatus: React.FC<TextStatusProps> = ({ status }) => {
  const classes = useStyles()

  if (status) {
    return <div className={ classes.greenText }>SIM</div>
  }

  return <div className={ classes.redText }>NÃO</div>
}

export default TextStatus
