import React from 'react'

import get from 'lodash/get'

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

import { useStyles } from './styles'

export interface ColumnType<T = any> {
  field: string,
  title: string,
  defaultValue?: string,
  render?: (item: T) => unknown,
}

export interface SimpleTableProps {
  items?: Array<any> | null,
  columns: Array<ColumnType>
  loading?: boolean,
  style?: React.CSSProperties,
}

const SimpleTable: React.FC<SimpleTableProps> = ({
  items = [],
  columns = [],
  loading = false,
  style = {}
}) => {
  const classes = useStyles()

  return (
    <Table style={ style }>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell className={ classes.tableHead } variant="head" padding="normal" key={ column.field }>
              { column.title }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        { loading && (
          <TableRow>
            <TableCell align="center" className={ classes.loading } colSpan={ columns.length }>
              <CircularProgress />
            </TableCell>
          </TableRow>
        ) }
        { !loading && items.map((item) => (
          <TableRow key={ item.id }>
            { columns.map((column) => (
              <TableCell padding="normal" key={ column.field }>
                { column.render
                  ? column.render(item)
                  : get(item, column.field, column.defaultValue) }
              </TableCell>
            )) }
          </TableRow>
        )) }
        { !loading && !items.length && (
          <TableRow>
            <TableCell align="center" className={ classes.loading } colSpan={ columns.length }>
              Ops... Não encontramos nenhum registro
            </TableCell>
          </TableRow>
        ) }
      </TableBody>
    </Table>
  )
}

export default SimpleTable
