import React from 'react'

import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'

import { Loader } from '@grupo-tiradentes/web'

import { useStyles } from './styles'

interface FilterWrapperProps {
  children: JSX.Element | Array<JSX.Element>,
  onFilter: () => void,
  onResetFilter: () => void,
  loading?: boolean,
  title?: string
}

const FilterWrapper: React.FC<FilterWrapperProps> = ({
  children,
  onFilter,
  onResetFilter,
  loading = false,
  title = 'Filtros'
}: FilterWrapperProps) => {
  const classes = useStyles()

  return (
    <Paper className={ classes.wrapper }>
      { !!title && (
        <>
          <Typography color="primary" className={ classes.title }>{title}</Typography>
          <Divider className={ classes.divider } variant="fullWidth" />
        </>
      )}
      {children}
      <Grid className={ classes.buttonContainer } container direction="row" spacing={ 2 } justifyContent="flex-end">
        <Grid item>
          <Button variant="outlined" onClick={ onResetFilter } color="secondary">Limpar</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={ onFilter } color="secondary">Filtrar</Button>
        </Grid>
      </Grid>

      { loading && <Loader /> }
    </Paper>
  )
}
export default FilterWrapper
