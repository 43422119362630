import qs from 'qs'

import {
  BaixasDePagamentoModel,
  RelatorioBaixaDePagamentoModel
} from '@mec/utils/models/baixasDePagamento'

import { api } from '..'

import routes from '../routes'
import { List } from '../types'
import {
  IBaixaDeBancoManual,
  IRegularizacao
} from './types'

const buscarBaixasDePagamentoService = async (
  params?: unknown
): Promise<List<BaixasDePagamentoModel>> => {
  const { data: response } = await api.get(routes.baixaCobranca, { params })
  return response
}

const buscarRelatoriosDeBaixasDePagamentoService = async (
  params?: unknown
): Promise<List<RelatorioBaixaDePagamentoModel>> => {
  const { data: response } = await api.get(`${ routes.baixaCobranca }/relatorio`, {
    params,
    paramsSerializer(param) {
      return qs.stringify(param, { arrayFormat: 'repeat' })
    }
  })
  return response
}

const buscarFiltrosMatriculaService = async (matricula: string): Promise<Array<string>> => {
  const { data: response } = await api.get(
    `${ routes.baixaCobranca }/listar-filtros-matricula`,
    { params: { matricula } }
  )
  return response
}

const buscarFiltrosAcordoService = async (acordo: string): Promise<Array<string>> => {
  const { data: response } = await api.get(
    `${ routes.baixaCobranca }/listar-filtros-acordo`,
    { params: { acordo } }
  )
  return response
}

const buscarFiltrosCpfService = async (cpf: string): Promise<Array<string>> => {
  const { data: response } = await api.get(
    `${ routes.baixaCobranca }/listar-filtros-cpf`,
    { params: { cpf } }
  )
  return response
}

const buscarFiltrosNomeAlunoService = async (nomeAluno: string): Promise<Array<string>> => {
  const { data: response } = await api.get(
    `${ routes.baixaCobranca }/listar-filtros-nome-aluno`,
    { params: { nomeAluno } }
  )
  return response
}

const baixaDeBancoManual = async (data: IBaixaDeBancoManual): Promise<any> => api.post(
  `${ routes.baixaCobranca }/baixa-manual`,
  data
)

const regularizacao = async (data: IRegularizacao): Promise<any> => api.post(
  `${ routes.baixaCobranca }/regularizar-acordo-cobranca`,
  data
)

const reprocessarBaixas = async (mensagem: string): Promise<any> => api.post(
  `${ routes.reprocessarAcordoCobranca }`,
  mensagem
)
export {
  buscarBaixasDePagamentoService,
  buscarFiltrosMatriculaService,
  buscarFiltrosAcordoService,
  buscarFiltrosCpfService,
  buscarFiltrosNomeAlunoService,
  baixaDeBancoManual,
  buscarRelatoriosDeBaixasDePagamentoService,
  regularizacao,
  reprocessarBaixas
}
