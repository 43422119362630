import React from 'react'

import { useStyles } from './styles'

const TableWrapper: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={ classes.table }>
      {children}
    </div>
  )
}

export default TableWrapper
