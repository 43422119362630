import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  divider: {
    height: 2,
    marginTop: 15,
    marginBottom: 25,
    backgroundColor: theme.palette.primary.light,
  },
  subtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: 20,
  },
}))
