import {
  FC,
  useState,
  useCallback
} from 'react'

import { Divider } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'

import Breadcrumbs from 'src/components/Breadcrumb'

import Footer from './Footer'
import MenuLeft from './MenuLeft'
import useStyles from './styles'
import Toolbar from './Toolbar'

const AuthenticatedLayout: FC = ({ children }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleToggleMenu = useCallback(
    () => setOpen((old) => !old),
    []
  )

  return (
    <div className={ classes.root }>
      <AppBar
        position="fixed"
        className={ classes.appBar }
      >
        <Toolbar
          open={ open }
          onToggleMenu={ handleToggleMenu }
        />
      </AppBar>

      <Drawer
        variant="permanent"
        className={ clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }) }
        classes={ {
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        } }
      >
        <div className={ classes.toolbar } />
        <MenuLeft open={ open } />
      </Drawer>

      <div className={ classes.contentContainer }>
        <div className={ classes.toolbar } />
        <main className={ classes.content }>
          <Breadcrumbs />
          <Divider variant="fullWidth" className={ classes.divider } />
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default AuthenticatedLayout
