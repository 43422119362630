import { ToastContainer } from 'react-toastify'

import { ReactKeycloakProvider } from '@react-keycloak/web'

import StoreProvider from '@mec/stores'
import Theme from '@mec/theme'
import {
  keycloak,
  keycloakProviderInitConfig
} from '@mec/utils/auth/auth'

import 'react-toastify/dist/ReactToastify.css'

import Routes from './routes'
import { store } from './store/store'

export default (): JSX.Element => (
  <StoreProvider store={ store }>
    <ReactKeycloakProvider authClient={ keycloak } initOptions={ keycloakProviderInitConfig }>
      <Theme>
        <Routes />

        <div id="portal" />

        <ToastContainer
          position="top-right"
          autoClose={ 3000 }
          hideProgressBar={ false }
          newestOnTop={ false }
          closeOnClick
          rtl={ false }
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Theme>
    </ReactKeycloakProvider>
  </StoreProvider>
)
