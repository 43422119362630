import React from 'react'

import databaseIcon from '@mec/web/src/assets/icons/database.svg'
import fileTextIcon from '@mec/web/src/assets/icons/file-text.svg'

import { useStyles } from './styles'

interface AcoesProps {
  id,
  onClickBaixaDeBanco: (id: string | number) => void,
  onClickRegularizacao: (id: string | number) => void,
}

const Acoes: React.FC<AcoesProps> = ({
  id, onClickBaixaDeBanco, onClickRegularizacao
}) => {
  const classes = useStyles()

  return (
    <div className={ classes.container }>
      {id.baixaAutomatica === false
      && (
      <>
        {id.situacaoPagamento !== 'R'
        && (
        <img
            // eslint-disable-next-line
          role="button"
          alt="Baixa de Banco"
          className={ classes.icon }
          tabIndex={ 0 }
          src={ databaseIcon }
          onClick={ () => onClickBaixaDeBanco(id) }
          onKeyDown={ () => onClickBaixaDeBanco(id) }
          title="Baixa de banco manual"
        />
        )}
        {id.situacaoPagamento !== 'B'
        && (
        <img
            // eslint-disable-next-line
          role="button"
          alt="Regularização"
          className={ classes.icon }
          tabIndex={ 0 }
          src={ fileTextIcon }
          onClick={ () => onClickRegularizacao(id) }
          onKeyDown={ () => onClickRegularizacao(id) }
          title="Regularização"
        />
        )}
      </>
      )}
    </div>
  )
}

export default Acoes
