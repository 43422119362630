import moment from 'moment'

import { ColumnType } from 'src/components/SimpleTable'

import Acoes from '../Acoes'

interface NovasParcelasNegociadasColumnsProps {
  onClickBaixaDeBanco: (id: string | number) => void,
  onClickRegularizacao: (id: string | number) => void,
}

const formatMoney = (
  value: number | string
) => Number(value).toLocaleString(
  'pt-br', { style: 'currency', currency: 'BRL' }
)

export const subtableColumnsParcelasAcordadas: Array<ColumnType> = ([
  {
    field: 'periodo',
    title: 'Período'
  },
  {
    field: 'tipoInadimplencia',
    title: 'Origem',
    render(item) {
      if (item.tipoInadimplencia === 'P') {
        return 'Parcela do curso do aluno'
      }

      if (item.tipoInadimplencia === 'T') {
        return 'Parcela de Negociação Título Avulso'
      }

      if (item.tipoInadimplencia === 'C') {
        return 'Parcela de Devolução de Cheque'
      }

      if (item.tipoInadimplencia === 'R') {
        return 'Quebra de Acordo'
      }

      return ''
    }
  },
  {
    field: 'parcela',
    title: 'Parcela'
  },
  {
    field: 'dataVencimento',
    title: 'Vencimento',
    render: (item) => moment(item.dataVencimento).add('hours', 3).format('DD/MM/YYYY'),
  },
  {
    field: 'valor',
    title: 'Valor',
    render: (item) => formatMoney(item.valor)
  },
])

export const getNovasParcelasNegociadasColumns = ({
  onClickBaixaDeBanco,
  onClickRegularizacao
}: NovasParcelasNegociadasColumnsProps): Array<ColumnType> => ([
  {
    field: 'parcela',
    title: 'Parcela',
  },
  {
    field: 'valorParcela',
    title: 'Valor Parcela',
    render: (item) => formatMoney(item.valor)
  },
  {
    field: 'dataVencimento',
    title: 'Vencimento',
    render: (item) => (item.dataVencimento !== null
      ? moment(item.dataVencimento).add('hours', 3).format('DD/MM/YYYY')
      : '')
  },
  {
    field: 'dataBaixa',
    title: 'Data Baixa',
    render: (item) => (item.dataBaixa !== null ? moment(item.dataBaixa).add('hours', 3).format('DD/MM/YYYY') : '')
  },
  {
    field: 'dataPagamento',
    title: 'Data do Pagamento',
    render: (item) => (item.dataPagamento !== null
      ? moment(item.dataPagamento).add('hours', 3).format('DD/MM/YYYY') : '')
  },
  {
    field: 'valorPago',
    title: 'Valor Pago',
    render: (item) => formatMoney(item.valorPago)
  },
  {
    field: 'valorJurosMulta',
    title: 'Juros e Multa',
    render: (item) => formatMoney(item.valorJurosMulta)
  },
  {
    field: 'tipoPagamento',
    title: 'Tipo de Pagamento',
  },
  {
    field: 'id',
    title: 'Ações',
    render: (item) => (
      <Acoes
        id={ item }
        onClickBaixaDeBanco={ onClickBaixaDeBanco }
        onClickRegularizacao={ onClickRegularizacao }
      />
    )
  }
])
