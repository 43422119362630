import React from 'react'

import {
  Divider,
  Typography
} from '@material-ui/core'

import { useStyles } from './styles'

interface SubtitleProps {
  underlined?: boolean,
  children: React.ReactChild | string,
}

const Subtitle: React.FC<SubtitleProps> = ({ underlined = false, children }) => {
  const classes = useStyles()

  return (
    <>
      <Typography
        variant="h6"
        color="primary"
        className={ classes.subtitle }
      >
        {children}
      </Typography>
      { underlined && (
        <Divider className={ classes.divider } variant="fullWidth" />
      )}
    </>
  )
}

export default Subtitle
