import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: 'capitalize',
    color: theme.palette.text.disabled,
  },
  disabled: {
    color: theme.palette.primary.main,
    cursor: 'default',
    fontWeight: 600,
  },
}))

export { useStyles }
