import moment from 'moment'

import { DataTableColumn } from '@grupo-tiradentes/web'

import { formatCPF } from '@mec/utils/format/formatCpf'
import { ParametroDeEnvioConflitoModel } from '@mec/utils/models/parametroDeEnvio'

import { ColumnType } from 'src/components/SimpleTable'

export const columns: DataTableColumn<ParametroDeEnvioConflitoModel>[] = [
  {
    field: 'id',
    title: 'Id',
    hidden: true,
  },
  {
    field: 'id',
    title: '',
    render: () => '',
    width: '10px',
  },
  { field: 'nomeLote', title: 'Nome lote' },
  {
    field: 'empresaParceiraTentativa.nomeFantasia',
    title: 'Empresa Parceira - Tentativa',
  },
  {
    field: 'empresaParceiraEnvio.nomeFantasia',
    title: 'Empresa Parceira - A ser enviado',
  },
  { field: 'nomeAluno', title: 'Aluno' },
  {
    field: 'cpf',
    title: 'CPF',
    render: (data: ParametroDeEnvioConflitoModel) => formatCPF(data.cpf),
  },
  { field: 'matricula', title: 'Matrícula' },
]

export const subtableColumns: Array<ColumnType> = [
  {
    field: 'modalidade',
    title: 'Modalidade Ensino',
    defaultValue: 'Não informado',
  },
  {
    field: 'parcela',
    title: 'Parcela Conflito',
    defaultValue: 'Não informado',
    render: (item) => `Parcela N.º ${ item.parcela }`,
  },
  {
    field: 'valor',
    title: 'Valor Conflito',
    defaultValue: 'Não informado',
    render: (item) => Number(item.valor).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }),
  },
  {
    field: 'conflito',
    title: 'Conflito',
    defaultValue: 'Não informado',
    render: (item) => `Conflito de ${ item.tipoConflito }`,
  },
  {
    field: 'dataEnvio',
    title: 'Data envio',
    defaultValue: 'Não informado',
    render: (item) => moment(item.dataEnvio).format('DD/MM/YYYY'),
  },
]
