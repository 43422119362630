import {
  FC,
  useCallback
} from 'react'

import MuiToolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowRightIcon from 'mdi-material-ui/ArrowRight'

import { useModal } from '@grupo-tiradentes/core'
import {
  IconButton,
  ConfirmModal
} from '@grupo-tiradentes/web'

import { keycloak } from '@mec/utils/auth/auth'

import logo from 'src/assets/images/logo.png'

import useStyles from './styles'

interface Props {
  open: boolean;
  onToggleMenu: () => void;
}

const Toolbar: FC<Props> = ({ onToggleMenu, open }) => {
  const classes = useStyles()

  const { createModal } = useModal()

  const handleLogout = useCallback(
    () => createModal({
      id: 'confirm-logout',
      Component: ConfirmModal,
      props: {
        title: 'Sair',
        text: 'Você realmente deseja sair do sistema?',
        onConfirm: () => keycloak.logout()
      }
    }),
    [createModal]
  )

  return (
    <MuiToolbar classes={ { root: classes.toolbarRoot } }>
      <div className={ classes.leftContainer }>
        <div className={ classes.brandContainer }>
          <div className={ classes.logoContainer }>
            <img
              alt="Grupo Tiradentes"
              src={ logo }
              className={ classes.logo }
            />
          </div>
          <div className={ classes.verticalDivider } />
          <div className={ classes.appNameContainer }>
            <Typography
              component="div"
              variant="caption"
              className={ classes.appName }
            >
              Empresas de Cobrança
            </Typography>
          </div>
        </div>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={ onToggleMenu }
          edge="start"
          className={ classes.menuButton }
          size="small"
        >
          {open ? <MenuIcon /> : <ArrowRightIcon />}
        </IconButton>
      </div>

      <div className={ classes.rightContainer }>
        <IconButton
          color="inherit"
          aria-label="logout"
          edge="end"
          tooltip="Sair"
          onClick={ handleLogout }
        >
          <ExitToAppIcon />
        </IconButton>
      </div>
    </MuiToolbar>
  )
}

export default Toolbar
