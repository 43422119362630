import {
  useMemo,
  createContext,
  useContext,
  FC
} from 'react'
import { useLocation } from 'react-router-dom'

import find from 'lodash/find'
import map from 'lodash/map'

import { ModalsProvider } from '@grupo-tiradentes/core'

import GuestScreenRouter from './routers/GuestScreenRouter'
import {
  Route,
  Routes,
  UseRoutes
} from './types'

const RoutesContext = createContext<UseRoutes>({
  currentPath: undefined,
  currentRoute: undefined,
  routes: undefined
})

export const useRoutes = (): UseRoutes => useContext(RoutesContext)

export const GuestRoutesProvider: FC = () => {
  const { pathname: currentPath } = useLocation()

  const routes = useMemo(
    (): Routes => ({
      login: {
        path: '/',
        exact: true,
        Component: () => <h1>login</h1>
      }
    }),
    []
  )

  const currentRoute = useMemo(
    (): Route => find(map(routes, (item) => item), { path: currentPath }) || {},
    [routes, currentPath]
  )

  const toolkit = useMemo(
    (): UseRoutes => ({
      currentPath,
      currentRoute,
      routes
    }),
    [currentPath, currentRoute, routes]
  )

  return (
    <RoutesContext.Provider value={ toolkit }>
      <ModalsProvider>
        <GuestScreenRouter />
      </ModalsProvider>
    </RoutesContext.Provider>
  )
}
