import React from 'react'

import {
  ThemeProvider,
  CssBaseline
} from '@material-ui/core'

import theme from './src/index.theme'

const Theme: React.FC = ({ children }): JSX.Element => (
  <>
    <ThemeProvider theme={ theme }>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </>
)

export default Theme
