import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  greenText: {
    color: '#1F8B24',
    border: '1px solid #1F8B24',
    backgroundColor: '#F6FFFF',
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    padding: 3,
  },
  redText: {
    color: '#FF5757',
    border: '1px solid #FF5757',
    backgroundColor: '#FFE2E2',
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    padding: 3,
  },
}))

export { useStyles }
