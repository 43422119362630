import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  listItemGutters: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 24,
  },
  listItemIcon: {
    minWidth: 30,
    color: theme.palette.primary.light,
  },
  listItemText: {
    margin: 0,
    '& > span': { fontSize: 14 },
  },
  item: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  itemColor: { color: '#808080' },
  itemActive: { color: `${ theme.palette.primary.light } !important` },
  subItemActive: { color: `${ theme.palette.primary.light } !important` },
  subItems: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listSubItem: { padding: '1.5px 30px 2.5px' },
  subItem: { color: '#808080' },
  subItemText: { '& > span': { fontSize: 13 } },
  listItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  listItemOpen: { padding: '10px 5px' },
  card: {
    margin: '0 12px 10px 19px',
    flex: 1,
    display: 'flex',
  },
  arrowIconButton: { padding: 0 },
}))
