import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 10,
  },
  icon: {
    cursor: 'pointer',
    width: 20,
    height: 20,
  },
}))

export { useStyles }
