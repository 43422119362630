import { makeStyles } from '@material-ui/core/styles'
import hexToRgba from 'hex-to-rgba'

import { drawerWidth } from '../styles'

export default makeStyles((theme) => ({
  menuButton: {
    marginLeft: '10px !important',
    backgroundColor: theme.palette.primary.main,
    border: '1px solid white',
    borderRadius: '10px',
  },
  toolbarRoot: {
    paddingLeft: 0,
    alignItems: 'unset',
    justifyContent: 'space-between',
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  brandContainer: {
    width: drawerWidth,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  logoContainer: {
    flex: 1.1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logo: {
    width: 85,
    filter: 'brightness(0) invert(1)',
  },
  verticalDivider: {
    height: '37.5%',
    width: 2,
    borderRadius: 50,
    backgroundColor: hexToRgba('#FFF', 0.3),
    margin: '0 12px',
  },
  appNameContainer: {
    flex: 0.9,
    display: 'flex',
    alignItems: 'center',
  },
  appName: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 10,
    letterSpacing: 0.1,
    lineHeight: 1.3,
    maxWidth: 100,
  },

  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))
