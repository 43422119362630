import { toast } from 'react-toastify'

import { Reload } from 'mdi-material-ui'

import { useModal } from '@grupo-tiradentes/core'
import {
  ConfirmModal,
  IconButton
} from '@grupo-tiradentes/web'

import { reprocessarBaixas } from '@mec/services/baixasDePagamento'
import { ReprocessarParametroEnvioService } from '@mec/services/parametroDeEnvio'
import { RelatorioBaixaDePagamentoDetalheModel, } from '@mec/utils/models/baixasDePagamento'
import { ParametroDeEnvioModel } from '@mec/utils/models/parametroDeEnvio'

interface StatusReprocessamentoProps {
  rowData: RelatorioBaixaDePagamentoDetalheModel,
  filtrar: () => void,
}

interface ReprocessarParametroEnvioProps {
  rowData: ParametroDeEnvioModel,
  buscarParametros: () => void
}
export const InfoBaixaPagamento = ({ item }: {item: RelatorioBaixaDePagamentoDetalheModel}) => {
  const { createModal } = useModal()

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="Info"
        edge="start"
        style={ {
          cursor: 'pointer',
          color: 'blue',
          textDecoration: 'underline',
          fontSize: '13px',
          marginRight: '13px'
        } }
        onClick={ () => createModal({
          id: `mostar ${ item.nomeAluno }`,
          Component: ConfirmModal,
          props: {
            title: 'Descrição do erro',
            text: `${ item.descricaoErro == null ? 'Descrição não esta dísponivel' : item.descricaoErro }`,
            contentContainerStyle: {
              width: 'auto',
              maxWidth: '600px',
              minWidth: '400px',
              height: 'auto',
              minHeight: '60px'
            },
            footerContainerStyle: { display: 'none' },
          },
        }) }
      >
        {item.erro}
      </IconButton>
    </div>
  )
}

export const ReprocessarParametroEnvio = ({ rowData, buscarParametros } : ReprocessarParametroEnvioProps) => {
  const { createModal } = useModal()

  async function reprocessarParametroEnvio(mensagem: string) {
    try {
      const data = JSON.parse(mensagem)
      await ReprocessarParametroEnvioService(data)
      toast.success('Reprocessamento enviado com sucesso')
      buscarParametros()
    } catch {
      toast.error('Reprocessamento falhou')
    }
  }

  return (
    rowData.reprocessarParametro?.podeReprocessar !== true
      ? (
        <Reload style={ { color: 'grey' } } />
      ) : (
        <IconButton
          color="default"
          aria-label="Reprocessar"
          edge="start"
          tooltip="Reprocessar parâmetro de envio"
          style={ { cursor: 'pointer' } }
          onClick={ () => createModal({
            id: `mostar ${ rowData.id }`,
            Component: ConfirmModal,
            props: {
              title: 'Realmente deseja reprocessar',
              text: 'Ao Reprocessar o item você enviará-lo automaticamente para a fila novamente!',
              onConfirm: () => reprocessarParametroEnvio(rowData.reprocessarParametro.mensagem),
              contentContainerStyle: {
                width: 'auto',
                maxWidth: '600px',
                minWidth: '400px',
                height: 'auto',
                minHeight: '60px'
              },
            },
          }) }
        >
          <Reload />
        </IconButton>
      )
  )
}

export const ReprocessarBaixa = ({
  rowData,
  filtrar
}: StatusReprocessamentoProps) => {
  const { createModal } = useModal()

  async function reprocessarParametroEnvio(data) {
    try {
      await reprocessarBaixas(data)
      toast.success('Reprocessamento enviado com sucesso')
      filtrar()
    } catch {
      toast.error('Reprocessamento falhou')
    }
  }

  return (
    rowData.reprocessado
      ? (
        <Reload style={ { color: 'grey' } } />
      ) : (
        <IconButton
          color="default"
          aria-label="Reprocessar"
          edge="start"
          tooltip="Reprocessar parâmetro de envio"
          style={ { cursor: 'pointer' } }
          onClick={ () => createModal({
            id: `mostar ${ rowData.nomeAluno }`,
            Component: ConfirmModal,
            props: {
              title: 'Realmente deseja reprocessar',
              text: 'Ao Reprocessar o item você enviará-lo automaticamente para a fila novamente!',
              onConfirm: () => reprocessarParametroEnvio({
                cnpjEmpresaCobranca: rowData.cnpjEmpresaCobranca,
                numeroAcordo: rowData.numeroAcordo
              }),
              contentContainerStyle: {
                width: 'auto',
                maxWidth: '600px',
                minWidth: '400px',
                height: 'auto',
                minHeight: '60px'
              },
            },
          }) }
        >
          <Reload />
        </IconButton>
      )
  )
}
