import React, {
  useState,
  useEffect,
  useCallback
} from 'react'
import { toast } from 'react-toastify'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  Grid,
  useTheme
} from '@material-ui/core'

import { AutocompleteInput } from '@grupo-tiradentes/web'

import {
  buscarFiltrosMatriculaService,
  buscarFiltrosAcordoService,
  buscarFiltrosCpfService,
  buscarFiltrosNomeAlunoService
} from '@mec/services/baixasDePagamento'
import { IBaixasDePagamentoFiltros } from '@mec/utils/models/baixasDePagamento'

import FilterWrapper from 'src/containers/FilterWrapper'

interface IFiltrosProps {
  onFilter: () => void,
  updateFilters: (filtros: unknown) => void
}

const FiltrosBaixasDePagamento: React.FC<IFiltrosProps> = ({ onFilter, updateFilters }) => {
  const [filtros, setFiltros] = useState<IBaixasDePagamentoFiltros>({})
  const theme = useTheme()
  const defaultColor = get(theme, 'palette.text.primary')
  const [matriculaOptions, setMatriculaOptions] = useState([])
  const [cpfOptions, setCpfOptions] = useState([])
  const [acordoOptions, setAcordoOptions] = useState([])
  const [alunoOptions, setAlunoOptions] = useState([])
  const [filtrosCargaInicial, setFiltrosCargaInicial] = useState({
    matricula: [],
    cpf: [],
    acordo: [],
    nomeAluno: []
  })

  const [timeoutId, setTimeoutId] = useState(null)

  useEffect(
    () => {
      updateFilters(filtros)
    },
    [updateFilters, filtros]
  )

  const handleChange = useCallback(
    (name: string, event: any) => {
      const { value } = event.target
      setFiltros((old) => ({ ...old, [name]: value }))
    },
    []
  )

  const cleanFilters = () => {
    setFiltros({})
    setTimeout(onFilter, 100)
  }

  const genericSearchAutocomplete = useCallback(
    async (method, value, callback, field) => {
      try {
        const response = await method(value)
        const formatedResponse = response.map((item: string) => ({ value: item, label: item }))
        if (filtrosCargaInicial[field] && isEmpty(filtrosCargaInicial[field])) {
          setFiltrosCargaInicial((old) => ({
            ...old,
            [field]: formatedResponse
          }))
        }
        callback((old) => ([
          ...old,
          ...formatedResponse.filter((item) => !old.includes(item.value))
        ]))
      } catch (e) {
        toast.error('Ocorreu um erro ao buscar os dados!', {
          position: 'top-right',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    },
    [filtrosCargaInicial]
  )

  const onChangeTextValue = useCallback(
    (e, method, callback, field = '', pasted = false) => {
      const value = !pasted ? get(e, 'target.value', '') : e.clipboardData.getData('Text').replace(/\D/gm, '')
      clearTimeout(timeoutId)
      const id = setTimeout(() => {
        genericSearchAutocomplete(method, value, callback, field)
      }, 500)

      setTimeoutId(id)
    },
    [genericSearchAutocomplete, timeoutId]
  )

  useEffect(
    () => {
      onChangeTextValue(null, buscarFiltrosMatriculaService, setMatriculaOptions, 'matricula')
      onChangeTextValue(null, buscarFiltrosCpfService, setCpfOptions, 'cpf')
      onChangeTextValue(null, buscarFiltrosAcordoService, setAcordoOptions, 'acordo')
      onChangeTextValue(null, buscarFiltrosNomeAlunoService, setAlunoOptions, 'nomeAluno')
    },
    // eslint-disable-next-line
    []
  )

  return (
    <FilterWrapper onFilter={ onFilter } onResetFilter={ cleanFilters }>
      <Grid
        container
        spacing={ 2 }
        justifyContent="space-around"
      >
        <Grid item lg={ 3 } md={ 3 } sm={ 6 } xs={ 12 }>
          <AutocompleteInput
            id="filtros-matricula-id"
            size="small"
            name="matricula"
            label="Matrícula"
            value={ filtros?.matricula }
            onChange={ (event) => handleChange('matricula', event) }
            onKeyPress={ (event) => onChangeTextValue(event, buscarFiltrosMatriculaService, setMatriculaOptions) }
            onPaste={
              (event) => onChangeTextValue(event, buscarFiltrosMatriculaService, setMatriculaOptions, '', true)
            }
            options={ matriculaOptions }
            inputProps={ { autocomplete: 'off' } }
            fullWidth
            showSearchIcon
            borderRadius={ 10 }
            defaultColor={ defaultColor }
          />
        </Grid>
        <Grid item lg={ 3 } md={ 3 } sm={ 6 } xs={ 12 }>
          <AutocompleteInput
            id="filtros-cpf"
            size="small"
            name="cpf"
            label="CPF"
            value={ filtros?.cpf }
            onChange={ (event) => handleChange('cpf', event) }
            onKeyPress={ (event) => onChangeTextValue(event, buscarFiltrosCpfService, setCpfOptions) }
            onPaste={ (event) => onChangeTextValue(event, buscarFiltrosCpfService, setCpfOptions, '', true) }
            options={ cpfOptions }
            inputProps={ { autocomplete: 'off' } }
            fullWidth
            showSearchIcon
            borderRadius={ 10 }
            defaultColor={ defaultColor }
          />
        </Grid>
        <Grid item lg={ 3 } md={ 3 } sm={ 6 } xs={ 12 }>
          <AutocompleteInput
            id="filtros-acordo"
            size="small"
            name="acordo"
            label="Acordo"
            value={ filtros?.acordo }
            onChange={ (event) => handleChange('acordo', event) }
            onKeyPress={ (event) => onChangeTextValue(event, buscarFiltrosAcordoService, setAcordoOptions) }
            onPaste={ (event) => onChangeTextValue(event, buscarFiltrosAcordoService, setAcordoOptions, '', true) }
            options={ acordoOptions }
            inputProps={ { autocomplete: 'off' } }
            fullWidth
            showSearchIcon
            borderRadius={ 10 }
            defaultColor={ defaultColor }
          />
        </Grid>
        <Grid item lg={ 3 } md={ 3 } sm={ 6 } xs={ 12 }>
          <AutocompleteInput
            id="filtros-aluno"
            size="small"
            name="nomeAluno"
            label="Aluno"
            value={ filtros?.nomeAluno }
            onChange={ (event) => handleChange('nomeAluno', event) }
            onKeyPress={
              (event) => onChangeTextValue(event, buscarFiltrosNomeAlunoService, setAlunoOptions)
            }
            onPaste={ (event) => onChangeTextValue(event, buscarFiltrosNomeAlunoService, setAlunoOptions, '', true) }
            options={ alunoOptions }
            inputProps={ { autocomplete: 'off' } }
            fullWidth
            showSearchIcon
            borderRadius={ 10 }
            defaultColor={ defaultColor }
          />
        </Grid>
      </Grid>
    </FilterWrapper>
  )
}

export default FiltrosBaixasDePagamento
