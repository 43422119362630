import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  card: {
    width: '100%',
    maxWidth: 750,
    overflow: 'visible',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px !important',
    position: 'relative',
  },
  spacer: { width: 50 },
  logo: { width: 120 },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  phonesContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  phoneIcon: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginRight: 5,
    transform: 'scaleX(-1)',
  },
  buttonScrollToTopContainer: {
    position: 'absolute',
    top: -15,
    right: 15,
    transition: 'opacity 0.4s ease',
  },
  buttonScrollToTop: {
    '&:hover': { backgroundColor: theme.palette.primary.main },
    backgroundColor: `${ theme.palette.primary.main } !important`,
    color: '#FFF !important',
    padding: 6,
  },
}))
