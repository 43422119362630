import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector
} from 'react-redux'

import createSagaMidleware from 'redux-saga'

import { configureStore } from '@reduxjs/toolkit'

import reducer from './reducers'
import sagas from './sagas'

const sagaMiddleware = createSagaMidleware()
const middlewares = [sagaMiddleware]

const store = configureStore({
  reducer,
  middleware: (getDefautMiddleware) => [
    ...getDefautMiddleware({ thunk: false }),
    ...middlewares,
  ],
})
// , serializableCheck: false

sagaMiddleware.run(sagas)

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
type AppDispatch = typeof store.dispatch;

const useAppDispatch = (): any => useDispatch<AppDispatch>()
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export {
  store,
  useAppDispatch,
  useAppSelector
}

export type {
  RootState,
  AppDispatch
}
