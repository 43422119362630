import {
  memo,
  useMemo,
  useEffect,
  FC
} from 'react'

import isEmpty from 'lodash/isEmpty'

import { useModal } from '@grupo-tiradentes/core'
import { Loader } from '@grupo-tiradentes/web'

import RouterSwitch from 'src/components/RouterSwitch'
import AuthenticatedLayout from 'src/layouts/AuthenticatedLayout'

import { useRoutes } from '../authenticated.routes'

const AuthenticatedScreenRouter: FC = (props) => {
  const { routes } = useRoutes()
  const { resetModals } = useModal()

  const waiting = useMemo(
    () => isEmpty(routes),
    [routes]
  )

  useEffect(
    () => {
      resetModals()
    },
    [resetModals]
  )

  return waiting
    ? <Loader />
    : (
      <AuthenticatedLayout>
        <RouterSwitch
          routes={ routes }
          { ...props }
        />
      </AuthenticatedLayout>
    )
}

export default memo(AuthenticatedScreenRouter)
