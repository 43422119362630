import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  table: {
    '& > .MuiPaper-root': { padding: 24 },
    '& > .MuiPaper-root > .MuiToolbar-root': {
      color: theme.palette.primary.main,
      paddingLeft: 0,
      fontWeight: 500,
      minHeight: 'fit-content',
    },
    '& .MuiSvgIcon-root': { color: theme.palette.primary.main },
    '& .MuiTableRow-root > :first-child': { paddingLeft: '0 !important' },
    '& .MuiTableRow-root > td': { padding: '18px 18px 21px 18px' },
    '& .MuiTableCell-footer': { paddingTop: 24 },
  },
}))

export { useStyles }
