import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    backgroundColor: '#F6F6F6',
    padding: '20px 35px 35px 35px',
  },
  tableHead: { fontWeight: 600 },
}))

export { useStyles }
