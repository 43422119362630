import { AxiosResponse } from 'axios'

import { ConvenioInstituicaoModel } from '@mec/utils/models/baixasDePagamento'

import { api } from '..'

import routes from '../routes'
import { List } from '../types'
import {
  BancoList,
  CriarEmpresaData,
  EmpresaData,
  EmpresaDataList
} from './types'

const criarEmpresaService = async (data: CriarEmpresaData):
Promise<AxiosResponse<EmpresaData>> => api.post(routes.empresa, data)

const alterarEmpresaService = async (data: EmpresaData):
Promise<AxiosResponse<EmpresaData>> => api.put(routes.empresa, data)

const buscarEmpresaService = async (params?: unknown):
Promise<AxiosResponse<List<EmpresaDataList>>> => api.get(routes.empresa, { params })

const deletarEmpresaService = async (id: number):
Promise<AxiosResponse<List<EmpresaDataList>>> => api.delete(`${ routes.empresa }/${ id }`)

const buscaBancosService = async (): Promise<AxiosResponse<Array<BancoList>>> => api.get(routes.bancos)

const buscarEmpresaPorIdService = async (id: string | number): Promise<EmpresaData> => {
  const { data: response } = await api.get(`${ routes.empresa }/${ id }`)
  return response
}

const buscarBancosDaInstituicaoService = async (
  instituicaoId: number | string
): Promise<ConvenioInstituicaoModel[]> => {
  const { data: response } = await api.get(`${ routes.convenioPorInstituicao }/${ instituicaoId }`)
  return response
}

export {
  criarEmpresaService,
  alterarEmpresaService,
  buscarEmpresaService,
  deletarEmpresaService,
  buscaBancosService,
  buscarEmpresaPorIdService,
  buscarBancosDaInstituicaoService
}
