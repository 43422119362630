import moment from 'moment'

import { DataTableColumn, } from '@grupo-tiradentes/web'

import {
  RelatorioBaixaDePagamentoDetalheModel,
  RelatorioBaixaDePagamentoModel
} from '@mec/utils/models/baixasDePagamento'

import { ColumnType } from 'src/components/SimpleTable'

import {
  InfoBaixaPagamento,
  ReprocessarBaixa
} from '../../../../components/Modals/ModalComponentDetailed'

export const columns: DataTableColumn<RelatorioBaixaDePagamentoModel>[] = [
  {
    field: 'id',
    title: 'Id',
    hidden: true,
  },
  {
    field: 'id',
    title: '',
    render: () => '',
    width: '10px',
  },
  {
    field: 'dataBaixa',
    title: 'Data',
    render: (item) => moment(item.dataBaixa).format('DD/MM/YYYY'),
  },
  {
    field: 'nomeEmpresaParceira',
    title: 'Empresa Parceira',
  },
  {
    field: 'nomeInstituicaoEnsino',
    title: 'IES',
  },
  { field: 'quantidadeTipo1', title: 'Quant. 1' },
  { field: 'quantidadeTipo2', title: 'Quant. 2' },
  { field: 'quantidadeTipo3', title: 'Quant. 3' },
  {
    field: 'valorTipo1',
    title: 'Total 1',
    render: (item) => Number(item.valorTipo1).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }),
  },
  {
    field: 'valorTipo2',
    title: 'Total 2',
    render: (item) => Number(item.valorTipo2).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }),
  },
  {
    field: 'valorTipo3',
    title: 'Total 3',
    render: (item) => Number(item.valorTipo2).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }),
  },
  { field: 'quantidadeErros', title: 'Quant. erro' },
]

export const subtableColumns = (filtrar): Array<ColumnType<RelatorioBaixaDePagamentoDetalheModel>> => [
  {
    title: '',
    field: '',
    render: (item) => (item.erro === 0 ? <p /> : <ReprocessarBaixa rowData={ item } filtrar={ filtrar } />),
  },
  {
    field: 'numeroLinha',
    title: 'N linha',
    defaultValue: 'Não informado',
  },
  {
    field: 'matricula',
    title: 'Matrícula',
    defaultValue: 'Não informado',
  },
  {
    field: 'nomeAluno',
    title: 'Nome',
    defaultValue: 'Não informado',
  },
  {
    field: 'numeroAcordo',
    title: 'Acordo',
    defaultValue: 'Não informado',
  },
  {
    field: 'parcela',
    title: 'Parcela',
    defaultValue: 'Não informado',
  },
  {
    field: 'dataVencimento',
    title: 'Data venc.',
    defaultValue: 'Não informado',
    render: (item) => moment(item.dataBaixa).format('DD/MM/YYYY'),
  },
  {
    field: 'valor',
    title: 'Valor',
    defaultValue: 'Não informado',
    render: (item) => Number(item.valor).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }),
  },
  {
    field: 'multa',
    title: 'Multa',
    defaultValue: 'Não informado',
    render: (item) => Number(item.multa).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }),
  },
  {
    field: 'juros',
    title: 'Juros',
    defaultValue: 'Não informado',
    render: (item) => Number(item.juros).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }),
  },
  {
    field: 'erro',
    title: 'Erro',
    defaultValue: 'Não informado',
    render: (item) => (item.erro !== 0
      ? (<InfoBaixaPagamento item={ item } />)
      : item.erro)
  }
]
