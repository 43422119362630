import React, { useMemo } from 'react'
import { useHistory } from 'react-router'

import { Breadcrumbs } from '@material-ui/core'
import classNames from 'classnames'

import { useStyles } from './styles'

const BreadcrumbsComponent: React.FC = () => {
  const classes = useStyles()
  const { location } = useHistory()

  const { pathname } = location
  const items = pathname.split('/').filter((item) => (
    !!item
    && Number.isNaN(Number(item))
  ))

  const customItems = useMemo(
    () => {
      if (items.length === 1) {
        return [...items, 'listar']
      }
      return items
    },
    [items]
  )

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      { customItems.map((route, index) => (
        <span
          className={ classNames({
            [`${ classes.capitalize }`]: true,
            [`${ classes.disabled }`]: customItems.length === (index + 1)
          }) }
        >
          {route.split('-').join(' ')}
        </span>
      )) }
    </Breadcrumbs>
  )
}

export default BreadcrumbsComponent
