import {
  useEffect,
  memo,
  FC
} from 'react'

import { useModal } from '@grupo-tiradentes/core'

import RouterSwitch from '../../components/RouterSwitch'
import { useRoutes } from '../guest.routes'

const GuestScreenRouter: FC = (props) => {
  const { routes } = useRoutes()
  const { resetModals } = useModal()

  useEffect(
    () => {
      resetModals()
    },
    [resetModals]
  )

  return (
  // <GuestLayout>
    <RouterSwitch
      routes={ routes }
      { ...props }
    />
  // </GuestLayout>
  )
}

export default memo(GuestScreenRouter)
