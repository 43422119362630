import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import { toast } from 'react-toastify'

import { buscarDetalhesConflitosParametroDeEnvioService } from '@mec/services/parametroDeEnvio'
import {
  ParametroDeEnvioConflitoDetalheModel,
  ParametroDeEnvioConflitoModel
} from '@mec/utils/models/parametroDeEnvio'

import SimpleTable from 'src/components/SimpleTable'

import { useStyles } from './styles'
import { subtableColumns } from './tableColumns'

interface SubtableDetailsProps {
  item: ParametroDeEnvioConflitoModel;
}

const SubtableDetails: React.FC<SubtableDetailsProps> = ({ item }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [conflitos, setConflitos] = useState<Array<ParametroDeEnvioConflitoDetalheModel>>([])

  const buscarDetalhesConflito = useCallback(
    async (id: string | number) => {
      try {
        setLoading(true)

        const response = await buscarDetalhesConflitosParametroDeEnvioService(id)
        setConflitos(response)
      } catch (e) {
        toast.error('Ocorreu um erro ao buscar os detalhes do conflito.',
          {
            position: 'top-right',
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
      } finally {
        setLoading(false)
      }
    },
    []
  )

  useEffect(
    () => {
      if (item.id) {
        buscarDetalhesConflito(item.id)
      }
    },
    [item, buscarDetalhesConflito]
  )

  return (
    <div className={ classes.container }>
      <SimpleTable items={ conflitos } columns={ subtableColumns } loading={ loading } />
    </div>
  )
}

export default SubtableDetails
