import { createTheme } from '@material-ui/core'
import hexToRgba from 'hex-to-rgba'

export default createTheme({
  palette: {
    primary: {
      main: '#066cc0',
      dark: '#0262B8',
    },
    secondary: {
      main: '#04afd6',
      contrastText: '#ffffff',
    },
    background: { default: '#eaedf5' },
    text: { primary: '#757575' },
  },
  overrides: {
    MuiCard: { root: { boxShadow: `0 1px 4px 0 ${ hexToRgba('#000', 0.14) }` } },
    MuiButton: { root: { boxShadow: `2px 2px 4px 0 ${ hexToRgba('#000', 0.14) }` }, },
    MuiPaper: {
      elevation1: { boxShadow: `0 1px 4px 0 ${ hexToRgba('#000', 0.14) }` },
      elevation2: { boxShadow: `0 1px 4px 0 ${ hexToRgba('#000', 0.14) }` },
      rounded: { borderRadius: 10 },
    },
    MuiInputBase: { formControl: { borderRadius: 1 } },
    MuiInput: { input: { color: 'red' } },
    MuiOutlinedInput: { root: { borderColor: 'red' } },
    MuiTableCell: {
      head: { fontWeight: 600, borderColor: '#EAEDF5' },
      body: { borderColor: '#EAEDF5' },
    },
    MuiTableSortLabel: { icon: { color: '#066cc0 !important' } },
    MuiFormControlLabel: { root: { color: '#066cc0' } },
  },
  typography: { button: { textTransform: 'none' } },
})
