/* eslint-disable no-param-reassign */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  get,
  isEmpty
} from 'lodash'

import {
  Grid,
  Typography,
  useTheme
} from '@material-ui/core'
import { DateRangeOutlined } from '@material-ui/icons'
import moment from 'moment'

import { useModal } from '@grupo-tiradentes/core'
import {
  Button,
  DateInputControlled,
  InputControlled,
  Modal,
  AutocompleteInputControlled,
  ConfirmModal
} from '@grupo-tiradentes/web'
import { Props as ModalProps } from '@grupo-tiradentes/web/types/src/Modal'

import { baixaDeBancoManual } from '@mec/services/baixasDePagamento'
import { buscarBancosDaInstituicaoService } from '@mec/services/empresa'
import { ConvenioInstituicaoModel } from '@mec/utils/models/baixasDePagamento'

import useStyles from './styles'

import './defaultStyles.css'

interface BaixaDePagamentoModalProps extends ModalProps {
  handleClose: () => void,
  baixaDePagamentoId: any,
  dadosCarregadosBaixaDeBanco: any,
  onConfirmarBaixaManual: (novosDadosTabela) => void
}

const BaixaDePagamentoModal: React.FC<BaixaDePagamentoModalProps> = ({
  id,
  open,
  handleClose,
  baixaDePagamentoId,
  dadosCarregadosBaixaDeBanco,
  onConfirmarBaixaManual,
  ...rest
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const defaultColor = get(theme, 'palette.text.primary')
  const [bancos, setBancos] = useState<Array<ConvenioInstituicaoModel>>([])
  const {
    control,
    handleSubmit,
    setValue,
  } = useForm({ mode: 'all' })
  const [dados, setDados] = useState<any>({})
  const [temDadosCarregados, setTemDadosCarregados] = useState(false)
  const { createModal } = useModal()

  useEffect(
    () => {
      if (!isEmpty(dadosCarregadosBaixaDeBanco)) {
        setTemDadosCarregados(true)
        setValue('motivo', dadosCarregadosBaixaDeBanco.motivo)
        setValue('dataBaixa', dadosCarregadosBaixaDeBanco.dataBaixa)
        setValue('valorBaixa', dadosCarregadosBaixaDeBanco.valorBaixa)
        setValue('codigoConvenio', dadosCarregadosBaixaDeBanco.codigoConvenio)
        setDados(dadosCarregadosBaixaDeBanco)
      }
    },
    [dadosCarregadosBaixaDeBanco, setValue]
  )

  const handleChange = useCallback(
    (name: string, event: any) => {
      const value = get(event, 'target.value')
      setDados((old) => ({ ...old, [name]: value }))
      setValue(name, value)
    },
    [setValue]
  )

  const onClose = useCallback(
    () => {
      createModal({
        id: 'delete-confirmation-modal',
        Component: ConfirmModal,
        props: {
          title: 'Atenção',
          text: 'Tem certeza que deseja cancelar?',
          onConfirm() {
            handleClose()
          }
        }
      })
    },
    [createModal, handleClose]
  )

  const onSubmitModal = async (data) => {
    setLoading(true)

    try {
      const payload = {
        idBaixaPagamento: baixaDePagamentoId.idBaixaPagamento,
        idParcelaNegociada: baixaDePagamentoId.id,
        dataPagamento: data.dataBaixa,
        valorPago: Number(data.valorBaixa),
        motivo: data.motivo,
        codigoConvenio: data.codigoConvenio
      }

      await baixaDeBancoManual(payload)

      const novosDadosTabela = {
        idBaixaPagamento: baixaDePagamentoId.idBaixaPagamento,
        idParcelaNegociada: baixaDePagamentoId.id,
        tipoPagamento: 'Pagamento Banco',
        dataPagamento: data.dataBaixa,
        valorPago: data.valorBaixa,
        situacaoPagamento: 'B',
        jurosMulta: data.valorBaixa - baixaDePagamentoId.valor,
        valorParcela: baixaDePagamentoId.valor
      }

      onConfirmarBaixaManual(novosDadosTabela)

      toast.success('Baixa de banco manual efetuada com sucesso',
        {
          position: 'top-right',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
    } catch (error) {
      toast.error('Erro ao salvar baixa de banco manual',
        {
          position: 'top-right',
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  const buscaBancos = async () => {
    try {
      setLoading(true)
      const response = await buscarBancosDaInstituicaoService(baixaDePagamentoId?.instituicaoId)

      setBancos(response)
    } catch (e) {
      toast.error('Ocorreu um erro ao buscar os bancos.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(
    () => {
      buscaBancos()
    },
    // eslint-disable-next-line
    []
  )

  const bancosOptions = useMemo(
    () => bancos.map((item) => ({
      label: `${ item.codigoBanco } - ${ item.nomeBanco } - ${ item.codigoAgencia
      } - ${ item.codigoConvenio } - ${ item.contaContabil }`,
      value: item.codigoConvenio
    })),
    [bancos]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={ (
        <Typography className={ classes.title } color="primary">
          Baixa de banco manual
        </Typography>
      ) }
      loading={ loading }
      disableFullScreen
      contentContainerStyle={ {
        padding: 24,
        paddingBottom: 12,
        width: '100%'
      } }
      headerProps={ { titleClass: classes.title } }
      maxWidth="md"
      FooterComponent={ ((): React.ReactNode => (
        <div className={ classes.footer }>
          {
            !temDadosCarregados && (
              <>
                <Button
                  onClick={ onClose }
                  disabled={ loading || temDadosCarregados }
                  variant="outlined"
                  color="secondary"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={ handleSubmit(onSubmitModal) }
                  disabled={ loading || temDadosCarregados }
                  style={ { marginLeft: 10 } }
                  variant="contained"
                  color="secondary"
                >
                  Salvar
                </Button>
              </>
            )
          }
        </div>
      )) as React.ComponentType }
      { ...rest }
    >
      <form onSubmit={ handleSubmit(onSubmitModal) }>
        <Grid container spacing={ 2 }>
          <Grid item lg={ 1 } sm={ 2 } xs={ 2 }>
            <div className={ classes.codeContainer }>
              <span className={ classes.spanText }>Código</span>
              <span className={ classes.spanValue }>{baixaDePagamentoId.numeroAcordo}</span>
            </div>
          </Grid>
          <Grid item lg={ 3 } md={ 4 } xs={ 4 }>
            <DateInputControlled
              label="Data baixa"
              name="dataBaixa"
              size="small"
              control={ control }
              rules={
                {
                  required: { value: true, message: 'Campo obrigatório' },
                  validate(value: string) {
                    if (moment(value).isAfter(moment(), 'day')) {
                      return 'Informe uma data passada'
                    }

                    return true
                  },
                }
              }
              onChange={ (e) => handleChange('dataBaixa', e) }
              autoComplete="off"
              fullWidth
              borderRadius={ 10 }
              defaultColor={ defaultColor }
              InputProps={ { endAdornment: (<DateRangeOutlined color="primary" />) } }
              value={ dados.dataBaixa }
              disabled={ temDadosCarregados }
            />
          </Grid>
          <Grid item lg={ 3 } md={ 4 } xs={ 4 }>
            <InputControlled
              label="Valor baixa"
              name="valorBaixa"
              size="small"
              type="number"
              rules={
                {
                  required: { value: true, message: 'Campo obrigatório' },
                  validate(value: number) {
                    if (Number(value) <= (baixaDePagamentoId.valor - 1)) {
                      return 'Valor menor que a parcela'
                    }

                    return true
                  },
                }
              }
              control={ control }
              fullWidth
              borderRadius={ 10 }
              defaultColor={ defaultColor }
              disabled={ temDadosCarregados }
            />
          </Grid>
          <Grid item lg={ 5 } md={ 4 } xs={ 4 }>
            <AutocompleteInputControlled
              id="codigoConvenio"
              size="small"
              name="codigoConvenio"
              label="Cód. Banco"
              value={ dados.codigoConvenio }
              onChange={ (e) => handleChange('codigoConvenio', e) }
              control={ control }
              options={ bancosOptions }
              inputProps={ { autocomplete: 'off' } }
              fullWidth
              showSearchIcon
              borderRadius={ 10 }
              defaultColor={ defaultColor }
              disabled={ temDadosCarregados }
            />
          </Grid>
        </Grid>
        <Grid container spacing={ 2 } style={ { marginTop: 15 } }>
          <Grid item xs={ 12 }>
            <InputControlled
              label="Motivo baixa"
              name="motivo"
              size="small"
              type="text"
              rules={ { required: { value: true, message: 'Campo obrigatório' } } }
              control={ control }
              fullWidth
              borderRadius={ 10 }
              defaultColor={ defaultColor }
              disabled={ temDadosCarregados }
            />
          </Grid>
        </Grid>
      </form>
    </Modal>
  )
}

export default BaixaDePagamentoModal
