import {
  useMemo,
  lazy,
  createContext,
  useContext,
  FC
} from 'react'
import { useLocation } from 'react-router-dom'

import { first } from 'lodash'
import filter from 'lodash/filter'
import find from 'lodash/find'
import filterFP from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'

import { useKeycloak } from '@react-keycloak/web'
import ConfiguracoesIcon from 'mdi-material-ui/CogOutline'
import BaixasDePagamentoIcon from 'mdi-material-ui/CurrencyUsd'
import PagamentoDiretoIcon from 'mdi-material-ui/InboxMultiple'
import EmpresaIcon from 'mdi-material-ui/OfficeBuildingOutline'
import RegrasIcon from 'mdi-material-ui/Security'

import { ModalsProvider } from '@grupo-tiradentes/core'

import ListagemBaixaDePagamento from 'src/pages/BaixasDePagamento/ListagemBaixaDePagamento'
import RelatorioBaixaDePagamento from 'src/pages/BaixasDePagamento/RelatorioBaixaDePagamento'
// import ListaDeLotesDeEnvio from 'src/pages/PagamentoDireto/ListaDeLotesDeEnvio'
import ListagemConflitos from 'src/pages/ParamentrosDeEnvio/ListagemConflitos'

import AuthenticatedScreenRouter from './routers/AuthenticatedScreenRouter'
import {
  Route,
  Routes,
  UseRoutes,
  Menu
} from './types'

const Empresa = lazy(() => import('src/pages/Empresa/CadastroEmpresa'))
const ListagemEmpresas = lazy(() => import('src/pages/Empresa/ListagemEmpresa'))

const Regra = lazy(() => import('src/pages/Regra/CadastroRegra'))
const ListagemRegras = lazy(() => import('src/pages/Regra/ListagemRegra'))

const ParametroEnvio = lazy(() => import('src/pages/ParamentrosDeEnvio/CadastroParametroDeEnvio'))
const ListagemParametroDeEnvio = lazy(() => import('src/pages/ParamentrosDeEnvio/ListagemParametroDeEnvio'))

const ListaDeLotesDeEnvio = lazy(() => import('src/pages/PagamentoDireto/ListaDeLotesDeEnvio'))
const CadastroDeLote = lazy(() => import('src/pages/PagamentoDireto/CadastroDeLote'))
const ConsultaIndividual = lazy(() => import('src/pages/PagamentoDireto/ConsultaIndividual'))

const RoutesContext = createContext<UseRoutes>({
  currentPath: undefined,
  currentRoute: undefined,
  routes: undefined,
  menus: []
})

export const useRoutes = (): UseRoutes => useContext(RoutesContext)

export const AuthenticatedRoutesProvider: FC = () => {
  const { pathname: currentPath } = useLocation()
  const { keycloak } = useKeycloak()
  const resourceAccessRoles = keycloak?.resourceAccess[process.env.REACT_APP_KEYCLOAK_RESOURCE]?.roles

  const routes = useMemo<Routes>(
    () => {
      const appRoutes: Routes = {
        home: {
          path: '/',
          exact: true,
          Component: ListagemEmpresas,
          roles: ['financeiro_holding']
        },

        listarEmpresas: {
          path: '/empresas',
          exact: true,
          Component: ListagemEmpresas,
          menu: 'empresas',
          title: 'Listagem de Empresas',
          roles: ['financeiro_holding']
        },
        criarEmpresa: {
          path: '/empresas/cadastrar',
          exact: true,
          Component: Empresa,
          menu: 'empresas',
          title: 'Cadastro de Empresas',
          roles: ['financeiro_holding']
        },
        editarEmpresa: {
          path: '/empresas/editar/:id',
          exact: true,
          Component: Empresa,
          roles: ['financeiro_holding']
        },

        listarRegras: {
          path: '/regras',
          exact: true,
          Component: ListagemRegras,
          menu: 'regras',
          title: 'Listagem de Regras',
          roles: ['financeiro_holding']
        },
        criarRegra: {
          path: '/regras/cadastrar',
          exact: true,
          Component: Regra,
          menu: 'regras',
          title: 'Cadastro de Regras',
          roles: ['financeiro_holding']
        },
        editarRegra: {
          path: '/regras/editar/:id',
          exact: true,
          Component: Regra,
          roles: ['financeiro_holding']
        },

        listarParametroEnvio: {
          path: '/parametros-de-envio',
          exact: true,
          Component: ListagemParametroDeEnvio,
          menu: 'configuracoes',
          title: 'Listagem de Parâmetros',
          roles: ['financeiro_holding']
        },
        criarParametroEnvio: {
          path: '/parametros-de-envio/cadastrar',
          exact: true,
          Component: ParametroEnvio,
          menu: 'configuracoes',
          title: 'Cadastro de Parâmetros',
          roles: ['financeiro_holding']
        },
        listarConflitos: {
          path: '/parametros-de-envio/conflitos',
          exact: true,
          Component: ListagemConflitos,
          menu: 'configuracoes',
          title: 'Listagem de Conflitos',
          roles: ['financeiro_holding']
        },
        editarParametroEnvio: {
          path: '/parametros-de-envio/editar/:id',
          exact: true,
          Component: ParametroEnvio,
          roles: ['financeiro_holding']
        },
        listarBaixasDePagamento: {
          path: '/baixas-de-pagamento',
          exact: true,
          menu: 'baixas-de-pagamento',
          Component: ListagemBaixaDePagamento,
          title: 'Listagem de Baixas de Pag.',
          roles: ['financeiro_holding', 'financeiro_unidadeapenas']
        },
        relatorioBaixaDePagamento: {
          path: '/baixas-de-pagamento/relatorio-de-baixa-de-pagamento',
          exact: true,
          menu: 'baixas-de-pagamento',
          Component: RelatorioBaixaDePagamento,
          title: 'Relatório de Baixas de Pag.',
          roles: ['financeiro_holding']
        },
        listaDeLotesDeEnvio: {
          path: '/pagamento-direto/lista-de-lotes-de-envio',
          exact: true,
          menu: 'pagamento-direto',
          Component: ListaDeLotesDeEnvio,
          title: 'Lista de Lotes de Envio',
          roles: ['financeiro_holding']
        },
        cadastroDeLote: {
          path: '/pagamento-direto/cadastro-de-lote',
          exact: true,
          menu: 'pagamento-direto',
          Component: CadastroDeLote,
          title: 'Cadastro de Lote',
          roles: ['financeiro_holding']
        },
        consultaIndividual: {
          path: '/pagamento-direto/consulta-individual',
          exact: true,
          menu: 'pagamento-direto',
          Component: ConsultaIndividual,
          title: 'Consulta Individual',
          roles: ['financeiro_holding']
        },
        editarLote: {
          path: '/pagamento-direto/editar/:id',
          exact: true,
          Component: CadastroDeLote,
          roles: ['financeiro_holding']
        },
      }

      const findRole = (role: string) => resourceAccessRoles?.some((c) => c === role)
      const responseOmitBy = omitBy(appRoutes, (route) => !find(route.roles, findRole))

      if (isEmpty(responseOmitBy)) {
        return {
          naoAutorizado: {
            path: '/',
            exact: true,
            Component: () => <h1>Você não tem permissão para acessar o sistema</h1>
          }
        }
      }
      return responseOmitBy
    },
    [resourceAccessRoles]
  )

  const menus = useMemo(
    (): Menu[] => {
      const items: Menu[] = [
        {
          key: 'empresas',
          title: 'Empresas',
          Icon: EmpresaIcon,
          defaultPath: routes.listarEmpresas?.path
        },
        {
          key: 'regras',
          title: 'Regras',
          Icon: RegrasIcon,
          defaultPath: routes.listarRegras?.path
        },
        {
          key: 'configuracoes',
          title: 'Parâmetros de Envio',
          Icon: ConfiguracoesIcon,
          defaultPath: routes.listarParametroEnvio?.path
        },
        {
          key: 'baixas-de-pagamento',
          title: 'Baixas de Pagamento',
          Icon: BaixasDePagamentoIcon,
          defaultPath: routes.listarBaixasDePagamento?.path
        },
        {
          key: 'pagamento-direto',
          title: 'Pagamento Direto',
          Icon: PagamentoDiretoIcon,
          defaultPath: routes.listarPagamentoDireto?.path
        }
      ]

      return flow(
        mapFP((item: Menu) => {
          const subMenu = filter(routes, (route) => route.menu && route.menu === item.key)
          return {
            exact: false,
            ...item,
            subMenu,
            defaultPath: item.defaultPath || first(subMenu)?.path
          }
        }),
        filterFP((item: Menu) => !isEmpty(item.subMenu))
      )(items)
    },
    [routes]
  )

  const currentRoute = useMemo(
    (): Route => find(map(routes, (item) => item), { path: currentPath }) || {},
    [routes, currentPath]
  )

  const toolkit = useMemo(
    (): UseRoutes => ({
      currentPath,
      currentRoute,
      routes,
      menus
    }),
    [currentPath, currentRoute, routes, menus]
  )

  return (
    <RoutesContext.Provider value={ toolkit }>
      <ModalsProvider>
        <AuthenticatedScreenRouter />
      </ModalsProvider>
    </RoutesContext.Provider>
  )
}
