import React, {
  useEffect,
  useState
} from 'react'

import {
  RelatorioBaixaDePagamentoDetalheModel,
  RelatorioBaixaDePagamentoModel
} from '@mec/utils/models/baixasDePagamento'

import SimpleTable from 'src/components/SimpleTable'

import { useStyles } from './styles'
import { subtableColumns } from './tableColumns'

interface SubtableDetailsProps {
  item: RelatorioBaixaDePagamentoModel;
  filtrar?: () => void
}

const SubtableDetails: React.FC<SubtableDetailsProps> = ({ item, filtrar }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [
    relatorioDeBaixaDePagamento,
    setRelatorioDeBaixaDePagamento
  ] = useState<Array<RelatorioBaixaDePagamentoDetalheModel>>([])

  useEffect(
    () => {
      setLoading(true)
      setRelatorioDeBaixaDePagamento(item.itens)
      setLoading(false)
    },
    // eslint-disable-next-line
    []
  )

  return (
    <div className={ classes.container }>
      <SimpleTable items={ relatorioDeBaixaDePagamento } columns={ subtableColumns(filtrar) } loading={ loading } />
    </div>
  )
}

export default SubtableDetails
